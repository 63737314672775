.my_nft_wrapper {
    width: 1455px;
    display: flex;
    flex-direction: column;

    .nft_type_tabs {
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #4f1a90;

        .rarity {
            display: flex;
            gap: 42px;
            color: #641cb2;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            div {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .filter_center {
            display: flex;
            gap: 24px;
            align-items: center;

            .dropdown {
                padding: 0 20px;
                box-sizing: border-box;
                width: auto;
                min-width: auto;
                display: flex;
                align-items: center;
                gap: 6px;
                color: #4f1a90;
                font-family: "Noto Sans";
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
            }
        }
    }

    .ant_tabs_nav {
        position: unset;
    }

    .nft_list_container {
        width: 100%;
        flex: 1;
        margin-bottom: 20px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;

        .nft_list {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 16px 51px;
            margin-top: 24px;

            .nft_item {
                width: 200px;
                height: 308px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;

                .nft_detail {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    width: 200px;
                    border-radius: 0px 0px 8px 8px;
                    background: rgba(255, 255, 255, 0.5);

                    .nft_id {
                        color: #6e41d1;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 6px 12px;
                    }
                    .line {
                        width: 100%;
                    }
                    .nft_type {
                        font-family: "Noto Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 6px 12px;
                        border: none;
                    }
                }

                .nft_button {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .nft_item_overlay,
                .nft_item_overlay0 {
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    top: 1px;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 8px 8px 0 0;
                }
                .nft_item_overlay0 {
                    background-color: rgba(255, 255, 255, 0);
                }

                .nft_img {
                    width: 200px;
                    height: 200px;
                    border-radius: 8px 8px 0 0;
                    overflow: hidden;
                    position: relative;
                    border-width: 3px;
                    :global antd-img {
                        width: 100%;
                        height: 100%;
                    }
                }

                :global {
                    .tags_common {
                        height: 26px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
