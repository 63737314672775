.public_popup {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    position: relative;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(65, 8, 127, 0.6);
        backdrop-filter: blur(1px);
    }

    .public_popup_bg {
        border-radius: 50px;
        background: #d0b6ee;
        box-shadow: 5px 5px 0px 0px #614568;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            box-sizing: border-box;
            position: relative;
            height: 50px;

            .title {
                color: #40077e;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 35px;
                line-height: 48px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                height: 48px;
            }

            .header_left {
                position: absolute;
                left: 40px;
                width: 30px;
                height: 30px;
            }

            .header_right {
                position: absolute;
                right: 40px;
                width: 30px;
                height: 30px;
            }
        }

        .header:empty {
            display: none;
        }

        .content {
            padding: 40px 60px 0 60px;
            box-sizing: border-box;
            min-width: 500px;
        }
    }
}
