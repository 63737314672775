.super_prize_grid {
    width: 390.38px;
    height: 390.38px;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 30% 30% 30%;
    grid-gap: 5%;

    .item {
        border-radius: 24px;
        background: #d0b6ee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: #4e1a90;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        :global {
            .gif_img {
                width: 77.709px;
                height: 70.292px;
            }
            .gif_open_img {
                width: 77.709px;
                height: 60.292px;
            }
        }
    }

    .current {
        background: #fff;
        cursor: pointer;
        white-space: pre;
    }

    .disabled {
        background: rgb(181, 181, 181);
        cursor: no-drop;
        white-space: pre;
    }
}
