.ad_slod {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .ad_slod_children {
        max-width: calc(100vw - 800px);
        max-height: calc(100vh - 120px);
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        margin-top: 100px;
    }

    .ad_slod_item {
        border-radius: 20px;
        background: rgba(25, 22, 41, 0.7);
        box-shadow: 10px 10px 15px 0px #463a74;
        backdrop-filter: blur(15px);
        position: relative;
        padding: 20px;
        cursor: pointer;
        box-sizing: border-box;

        .ad_slod_close_button {
            border-radius: 8px;
            background: #272640;
            width: 29px;
            height: 29px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 13px;
            right: 13px;

            .ad_slod_close_button_img {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
            }
        }

        .ad_slod_content {
            color: #fff;
            text-align: center;
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px; /* 150% */
        }

        .button_common {
            height: 35px;
            font-size: 20px;
            text-decoration: none;
        }

        .ad_img1:hover,
        .ad_img2:hover {
            transform: scale(1.1);
        }
    }

    .ad_slod_header {
        position: absolute;
        top: 50px;
        width: 674px;
        height: 110px;
        flex-shrink: 0;
        display: flex;
        align-items: end;
        justify-content: space-between;
        gap: 10px;

        .ad_img2 {
            width: 89px;
            height: 75px;
            flex-shrink: 0;
        }

        .ad_img1 {
            width: 92.932px;
            height: 32px;
            flex-shrink: 0;
        }
    }

    .ad_slod_left {
        position: absolute;
        left: 100px;
    }
    .ad_slod_right {
        position: absolute;
        right: 100px;
    }

    .ad_slod_center {
        display: flex;
        flex-direction: column;
        width: 250px;
        height: 545px;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .ad_slod_content {
            width: 80%;
        }

        .ad_img1 {
            width: 180.055px;
            height: 62px;
            flex-shrink: 0;
        }

        .ad_img2 {
            width: 229px;
            height: 194px;
            flex-shrink: 0;
        }
    }

    .ad_slod_center_center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 50vw;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10vh 10vw;
    }
}
