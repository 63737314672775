.my_ticker_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
        display: flex;
        height: 68px;
        box-sizing: border-box;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #4f1a90;

        .ticker_type {
            display: flex;
            align-items: center;
            gap: 42px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                color: #641cb2;
                font-family: "Noto Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                cursor: pointer;
            }
        }
    }

    .table_div {
        height: 615px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 20px;
    }
}
