.tips_modal {
    min-width: 600px;

    .gameinvalid {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #fb402d;
        font-family: "Noto Sans";
        font-size: 20px;
    }

    .tips_des {
        color: #40077e;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 100px;
        max-width: 800px;
    }

    .game_over {
        display: flex;
        align-items: center;
        flex-direction: column;

        .game_over_list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px 160px;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 50px;
                color: #40077e;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                height: 56px;

                .avatar {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    height: 50px;
                    width: 200px;

                    .nick_name {
                        max-width: 140px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .ant-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }

                .point {
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 600;
                    width: 120px;
                    height: auto;
                    text-align: right;
                }

                .close_button {
                    position: absolute;
                    right: -150px;
                    width: 32px;
                    height: 32px;

                    .ant-image {
                        mix-blend-mode: plus-lighter;
                    }
                }
            }

            .myself {
                color: #fff;
                position: relative;

                .point {
                    color: #fff;
                }
            }
        }
    }

    .game_btn_container,
    .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 40px;
        margin-top: 100px;
        margin-bottom: 60px;
        cursor: pointer;

        .button_common {
            height: 65px;
            font-size: 24px;
        }
    }

    .game_btn_container {
        margin-top: 40px;
    }
}

.share_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px 100px;
    width: 360px;
    padding: 60px 220px;

    .share_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 21px;
        cursor: pointer;

        .ant-image {
            width: 126px;
            height: 125px;
        }

        .name {
            color: #40077e;
            text-align: right;
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}
