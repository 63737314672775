.buy_vsys {
    width: 1080px;
    height: 654px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 33px;
    margin-bottom: 40px;

    .buy_vsys_item {
        display: flex;
        width: 338px;
        height: 196px;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.5);
        box-sizing: border-box;

        .logo_img {
            max-height: 80px;
            max-width: 280px;
        }
    }
}
