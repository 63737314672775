.personal-center-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 60px;

    .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;

        .avatar_div {
            position: relative;

            .avatar {
                background: #c0cedc;
                border-radius: 20px;
                width: 200px;
                height: 200px;
            }
        }

        .uid {
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            height: 32px;
            width: 148px;
            margin-top: 6px;
            margin-bottom: 12px;
        }

        .left_bt {
            border-radius: 17px;
            margin-top: 12px;
            width: 135px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px;
            background: linear-gradient(to bottom, #a3a2a3, #cfcecf);
            cursor: pointer;
        }

        .left_bt:active,
        .left_bt:hover,
        .left_bt_hover {
            background: linear-gradient(to bottom, #e3a521, #e9ba54);
            width: 140px;
            height: 34px;
            border-radius: 17px;
        }
    }

    .right-side {
        flex: 1;
        width: 1455px;
        height: 826px;

        .my-nft {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .top-tab {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 32px;
                border-bottom: 1px solid #4f1a90;

                .tab_right {
                    display: inline-flex;
                    align-items: flex-start;
                    gap: 52px;

                    .button {
                        display: flex;
                        padding: 10px 28px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 20px;
                        background: linear-gradient(178deg, #989898 5.68%, #e3e2e4 98.16%);
                        box-shadow: 3px 3px 0px 0px #a09fa0;
                        color: #4f1a90;
                        font-family: "Noto Sans";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        cursor: pointer;
                    }

                    .button:hover {
                        transform: scale(1.05);
                        opacity: 0.9;
                    }

                    .button_hover {
                        background: linear-gradient(178deg, #4f1a90 5.68%, #8428f4 98.16%);
                        box-shadow: 3px 3px 0px 0px #541c9b;
                        color: #fff;
                    }
                }

                .tab_left {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .price_ic {
                        width: 50px;
                        height: 50px;
                    }

                    .close_button {
                        width: 50px;
                        height: 50px;
                    }
                    .price_usd {
                        color: #40077e;
                        font-family: "Noto Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 500px;
                    }
                    .price_tet {
                        color: #40077e;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        max-width: 500px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

            .right_content {
                width: 1455px;
                height: 746px;
            }
        }
    }
}

.select_ic {
    width: 100px;
    height: 100px;
    background: url("../../../assets/hall/select_nft_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
