.matchmaking {
    position: relative;
    height: 800px;
    width: 1830px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .vs {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 368px;
        height: 455px;
        background: url("../../assets/hall/vs.png") 100% no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        justify-content: center;
    }
    .left,
    .right {
        display: flex;
        flex-direction: column;
        gap: 47px;
        height: 604px;
        justify-content: center;
        width: 50%;
    }
    .item {
        position: relative;
        width: 453px;
        height: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        .ant-image {
            position: absolute;
            width: 113px;
            height: 113px;
            top: -50px;
            border-radius: 50%;
            overflow: hidden;
        }
        .username {
            height: 41px;
            width: 240px;
            font-size: 20px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #4c2f52;
            line-height: 41px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .points {
            height: 55px;
            font-size: 26px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #ffffff;
            line-height: 55px;
        }
    }
    .left {
        .item {
            background: url("../../assets/hall/slot_left.png") 100% no-repeat;
            background-size: 100% 100%;
            padding-left: 200px;
            .ant-image {
                left: 32px;
            }
        }
        .item:nth-child(4) {
            margin-left: 272px;
        }
        .item:nth-child(3) {
            margin-left: 166px;
        }
        .item:nth-child(2) {
            margin-left: 106px;
        }
        .item:nth-child(1) {
            margin-right: 412px;
        }
    }
    .right {
        .item {
            background: url("../../assets/hall/slot_right.png") 100% no-repeat;
            background-size: 100% 100%;
            padding-left: 64px;
            .ant-image {
                right: 32px;
            }
        }
        .item:nth-child(4) {
            margin-left: 140px;
        }
        .item:nth-child(3) {
            margin-left: 246px;
        }
        .item:nth-child(2) {
            margin-left: 306px;
        }
        .item:nth-child(1) {
            margin-left: 412px;
        }
    }
}
.matching {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    gap: 132px;

    .matching-item {
        border: 1px solid #6c7172;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 17px;
        width: 388px;
        height: 388px;
        position: relative;

        .matching_bg {
            width: 354px;
            height: 354px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            background: url("../../assets/hall/matching_bg.png") 100% no-repeat;
            background-size: 100% 100%;
        }
        .matching_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;

            .content_text,
            .ant-statistic .ant-statistic-content {
                font-size: 48px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #ffffff;
                line-height: 80px;
            }
        }
    }

    .button_common {
        height: 65px;
        font-size: 24px;
    }
}
