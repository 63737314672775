.tetrisBackMovement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    .loader {
        text-align: center;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        span {
            -webkit-animation: block_l 60s infinite linear;
            -moz-animation: block_l 60s infinite linear;
            animation: block_l 60s infinite linear;
            display: none;
            opacity: 0;
        }
    }

    // 7,12
    .loader span:nth-child(2),
    .loader span:nth-child(6) {
        display: block;
        -webkit-animation-delay: 0.7s;
        -moz-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
    .loader span:nth-child(1) {
        display: block;
        -webkit-animation-delay: 2.7s;
        -moz-animation-delay: 2.7s;
        animation-delay: 2.7s;
    }
    // 4,11
    .loader span:nth-child(3) {
        display: block;
        -webkit-animation-delay: 30s;
        -moz-animation-delay: 30s;
        animation-delay: 30s;
    }
    .loader span:nth-child(4),
    .loader span:nth-child(5) {
        display: block;
        -webkit-animation-delay: 32.8s;
        -moz-animation-delay: 32.8s;
        animation-delay: 32.8s;
    }

    .loader span:nth-child(4n + 1) {
        width: 137px;
        height: 90px;
        background: url(../../../assets/icon/block_l_bottom.png) no-repeat;
        background-size: 100% 100%;
    }
    .loader span:nth-child(4n + 2) {
        width: 137px;
        height: 90px;
        background: url(../../../assets/icon/block_l_top.png) no-repeat;
        background-size: 100% 100%;
    }
    .loader span:nth-child(4n + 3) {
        width: 90px;
        height: 137px;
        background: url(../../../assets/icon/block_l_left.png) no-repeat;
        background-size: 100% 100%;
    }
    .loader span:nth-child(4n + 4) {
        width: 90px;
        height: 137px;
        background: url(../../../assets/icon/block_l_right.png) no-repeat;
        background-size: 100% 100%;
    }

    @keyframes block_l {
        0% {
            opacity: 1;
            -webkit-transform: translate(0, 0vh) rotateZ(0deg);
        }
        24.9% {
            opacity: 1;
            -webkit-transform: translate(0, 49.8vh) rotateZ(0deg);
        }
        25% {
            opacity: 1;
            -webkit-transform: translate(0, 50vh) rotateZ(90deg);
        }
        49.9% {
            opacity: 1;
            -webkit-transform: translate(0, 99.8vh) rotateZ(90deg);
        }
        50% {
            opacity: 1;
            -webkit-transform: translate(0, 100vh) rotateZ(180deg);
        }
        74.9% {
            opacity: 1;
            -webkit-transform: translate(0, 149.8vh) rotateZ(180deg);
        }
        75% {
            opacity: 1;
            -webkit-transform: translate(0, 150vh) rotateZ(270deg);
        }
        99.9% {
            opacity: 1;
            -webkit-transform: translate(0, 199.8vh) rotateZ(270deg);
        }
        100% {
            opacity: 1;
            -webkit-transform: translate(0, 200vh) rotateZ(360deg);
        }
    }

    // @-webkit-keyframes
    // @-moz-keyframes
}
