#root {
    // button
    .button_common {
        border-radius: 25px;
        box-shadow: -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
        color: rgba(255, 255, 255, 0.9);
        text-shadow:
            #7c7c7c 1px 0 0,
            #7c7c7c 0 1px 0,
            #7c7c7c -1px 0 0,
            #7c7c7c 0 -1px 0;
        cursor: pointer;
        font-family: "Noto Sans";
        font-weight: 900;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 180px;
        min-height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        gap: 10px;

        .ant-image {
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .button_shadow {
        box-shadow:
            -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset,
            4px 38px 62px 0px rgba(0, 0, 0, 0.5);
    }
    .primary_button {
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #df9800;
    }
    .secondary_button {
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #721ac9;
    }
    .disabled_button {
        cursor: no-drop;
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #7c7c7c;
    }
    .warning_button {
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #c51010;
    }
    .arrow_button {
        flex-shrink: 0;
        border-radius: 50%;
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.7) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #9751f2;
        box-shadow:
            0px -3px 8px 0px rgba(255, 255, 255, 0.25) inset,
            6px 9px 9px 0px rgba(255, 255, 255, 0.1) inset,
            0px 2px 2px 0px rgba(255, 255, 255, 0.6) inset,
            -10px -12px 18px 0px rgba(0, 0, 0, 0.25) inset,
            3px 4px 8px 0px rgba(255, 255, 255, 0.7) inset,
            0px 17px 23px 0px rgba(10, 10, 10, 0.25);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        aspect-ratio: 1 / 1;
        .ant-image {
            width: 50%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .close_button {
        background-color: transparent;
        border: none;
        border-radius: 10px;
        min-width: 30px;
        min-height: 30px;
        background: radial-gradient(
                76.16% 76.16% at 31.97% 19.67%,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(255, 255, 255, 0) 69.79%,
                rgba(255, 255, 255, 0) 100%
            ),
            #721ac9;
        box-shadow:
            -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset,
            4px 38px 62px 0px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        .ant-image {
            width: 50%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .selected_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        background-image: url("../assets/public_icon/selected_icon.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
    }
    .unselected_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        background-image: url("../assets/public_icon/unselected_icon.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
    }
    .checked_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        background-image: url("../assets/public_icon/checked_icon.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 45px;
        width: 45px;
        aspect-ratio: 1 / 1;
    }
    .unchecked_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 0;
        background-image: url("../assets/public_icon/unchecked_icon.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 45px;
        width: 45px;
        aspect-ratio: 1 / 1;
    }

    // tags
    .tags_common {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        left: 3px;
        top: 1px;
        padding: 0 10px;
        font-family: "Noto Sans";
        font-weight: 400;
        color: #ffffff;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        min-width: 100px;
    }
    .buy_tag {
        background: linear-gradient(to bottom, #e3a521, #e9ba54);
        box-shadow: 0 2px 2.83px rgba(166, 144, 56, 0.988);
    }
    .sell_tag {
        background: linear-gradient(to bottom, #19961e, #27b740);
        box-shadow: 0 2px 2.83px rgba(13, 149, 24, 1);
    }
    .expired_tag {
        background: linear-gradient(to bottom, #cc1a1a, #c81d12);
        box-shadow: 0 2px 2.83px rgba(113, 40, 30, 1);
    }
    .remove_tag {
        background: linear-gradient(to bottom, #f16e30, #c85620);
        box-shadow: 0 2px 1.415px rgba(126, 58, 20, 0.5);
    }
    .activate_tag {
        background: linear-gradient(to bottom, #4c257b, #8128f0);
        box-shadow: 0 2px 2.83px rgb(57, 49, 77);
    }
    .disabled_tag {
        background: linear-gradient(to bottom, #4f4f4f, #848484);
        box-shadow: 0 2px 2.83px rgb(71, 70, 72);
        cursor: no-drop;
    }

    // title
    .web_title {
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: rgba(64, 7, 126, 0.2);
        color: #fff;
        text-align: center;
        text-shadow:
            #7c7c7c 1px 0 0,
            #7c7c7c 0 1px 0,
            #7c7c7c -1px 0 0,
            #7c7c7c 0 -1px 0;
        font-family: "Noto Sans";
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.5px;
        line-height: 70px;
        padding: 0px 20px 0px 20px;
        z-index: 1;
    }

    // form_input
    .form_input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        line-height: 65px;
        border-radius: 16px;
        border: 2px solid rgba(255, 255, 255, 0.15);
        background: #40077e;
        background-size: 100% 100%;
        position: relative;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #ffffff;
        font-size: 24px;
        padding: 0 0 0 10px;
        box-sizing: border-box;
        .ant-input {
            max-width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
        }
        .ant-input {
            border: none;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #ffffff;
            font-size: 24px;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .ant-input::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        // input suffix
        .ant-input-suffix {
            height: 100%;
            display: flex;
            align-items: center;
            .ant-image {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 24px;
                img {
                    width: 55px;
                }
            }
        }
    }
}

//ant-dropdown
.ant-dropdown .ant-dropdown-menu {
    border-radius: 0px 20px 20px 20px;
    background: #4f1a90;
    overflow: hidden;
    padding: 0;
    .ant-dropdown-menu-item {
        background: #4f1a90;
        color: #fff;
        font-family: "Noto Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        line-height: 30px;
        text-align: left;
        border-radius: 0;
        padding: 10px 20px;
    }
    .ant-dropdown-menu-item:hover {
        background: rgba(255, 255, 255, 0.4);
        color: #4f1a90;
    }
}

//ant-notification
.ant-notification-notice-wrapper {
    background: rgba(255, 255, 255, 0) !important;
}
.ant-notification .notification {
    width: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin: 0 !important;
    padding: 0 10px !important;
    text-align: center;
    .ant-notification-notice-message {
        padding-inline-end: 0;
        font-size: 22px;
        font-weight: normal;
        color: #ffffff;
        padding: 10px 0;
        margin-bottom: 0;
    }
    .ant-notification-notice-close {
        display: none;
    }
    a {
        text-decoration: underline;
        color: #fff;
    }
}

// ant-input
.ant-input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px transparent inset !important;
}
.ant-input:-internal-autofill-previewed {
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s !important;
}
.ant-input:-internal-autofill-selected {
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

.ant-switch,
.ant-switch:hover {
    background-image: url("../assets/public_icon/off_icon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    border-radius: 20px;
}
.ant-switch .ant-switch-handle {
    overflow: hidden;
    border-radius: 50%;
}
.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: transparent;
    background-image: url("../assets/public_icon/on_icon.svg") !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    border-radius: 20px;
}

//nft
.M {
    $color: #fff;
    border: 1px solid $color;
    color: $color;
    .subheading {
        background-color: $color;
        width: 100%;
        height: 100%;
    }
}
.N {
    $color: red;
    border: 1px solid $color;
    color: $color;
    .subheading {
        background-color: $color;
        width: 100%;
        height: 100%;
    }
}
.R {
    $color: #24c8e6;
    border: 1px solid $color;
    color: $color;
    .subheading {
        background-color: $color;
        width: 100%;
        height: 100%;
    }
}
.SR {
    $color: #7535ff;
    border: 1px solid $color;
    color: $color;
    .subheading {
        background-color: $color;
        width: 100%;
        height: 100%;
    }
}
.SSR {
    $color: #ff8105;
    border: 1px solid $color;
    color: $color;
    .subheading {
        background-color: $color;
        width: 100%;
        height: 100%;
    }
}

// common icon
.series_ic {
    width: 19px;
    height: 21px;
    background: url("../assets/public_icon/series_ic.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.price_ic {
    width: 30px;
    height: 30px;
    background: url("../assets/public_icon/token_icon.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.price_info_ic {
    width: 24px;
    height: 24px;
    background: url("../assets/public_icon/price_info_ic.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.dropdown_ic {
    width: 16px;
    height: 10px;
    background: url("../assets/public_icon/dropdown_ic.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.copy_ic {
    width: 24px;
    height: 24px;
    background: url("../assets/public_icon/copy_icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.copy_ic:hover {
    background: url("../assets/public_icon/copy_hover_icon.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.gif_img {
    background: url("../assets/public_icon/gif_img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.gif_open_img {
    background: url("../assets/public_icon/gif_open_img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warning_icon {
    width: 24px;
    height: 24px;
    background: url("../assets/public_icon/warning_icon.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

// git_icon
@-webkit-keyframes imageAnim {
    0% {
        opacity: 1;
    }
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        transform: scale(1);
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        opacity: 0.5;
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes imageAnim {
    0% {
        opacity: 1;
    }
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
        transform: scale(1);
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        opacity: 0.5;
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.invite {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 10px #fff;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    animation: imageAnim 0.5s infinite;
    -webkit-animation-name: imageAnim;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -webkit-animation-timing-function: ease;
    -webkit-animation-play-state: running;

    .ant-image {
        width: 100%;
        height: 100%;
    }

    .gif_icon {
        position: absolute;
        right: -3px;
        top: -5px;
        width: 44px;
        height: 50px;
    }
}
