.room-field {
  padding: 1vh;
  flex: 1 1 0%;
  display: flex;
  overflow: hidden;
}

.left-field {
  flex: 1 1 0%;
  padding: 1vh;
  margin: 5px;
  // background-color: #bccdd6b3;
  border-radius: 2px 60px 2px 60px;
  display: flex;
  align-items: flex-start;
  // flex-direction: column;
  justify-content: center;
  // width: 100px;
  // border: 1px solid red;

  .odds {
    width: 200px;
    height: 118px;
    background-image: url("../../assets/board/odds.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    right: 20px;
    top: 10px;
    z-index: -9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: "Noto Sans";
    font-weight: normal;
    color: #fff;
    line-height: 35px;
    text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);

    .num {
      font-size: 30px;
    }
  }

  .prop_container {
  }
}

.create-room {
  align-self: center;
  width: 70%;
  display: flex;
  padding: 1vh;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: #bccdd6b3;
}

.room-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.room-button {
  width: 12rem;
  height: 3vh;
  background: none;
  border: 0px;
  outline: none;
  opacity: 0;
  transition-delay: 5ms;
}

.room-button:enabled {
  opacity: 1;
  transition: all 1s;
  cursor: pointer;
}

.room-button:disabled {
  opacity: 0;
  transition: all 0.8s;
}

.room-p {
  font-size: 2vh;
  font-family: "ArcadeClassic";
  margin: 0;
}

.room-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.room-list-h2 {
  margin: 0;
  font-size: 3vh;
  color: white;
}

.header-list {
  height: 6%;
  display: flex;
  align-items: center;
  border: 3px solid white;
  border-bottom: unset;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 5px 5px 0px 0px;
}

.list-name {
  display: flex;
  justify-content: center;
  flex: 3 1 0%;
}

.list-attribute {
  display: flex;
  justify-content: center;
  flex: 1 1 0%;
}

.header-p {
  font-size: 2vh;
}

.body-list {
  height: 100%;
  border: 3px solid white;
  border-radius: 0px 0px 5px 40px;
  overflow-y: auto;
}

.right-field {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .game_title {
    width: 442px;
    height: 152px;
  }

  .card_container {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    // justify-content: center;

    .card_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 49px;

      .card_img {
        width: 132px;
        height: 132px;
        background-image: url("../../assets/board/dj_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // cursor: pointer;

        .red_point {
          position: relative;
          top: -50px;
          right: 1px;
          font-family: "Noto Sans";
          font-size: 20px;
          font-weight: 400;
          line-height: 23px;
          color: #ffffff;
          background-image: url("../../assets/hall/red_point.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .blank_area {
          width: 35px;
          height: 35px;
        }

        .props_icon {
          width: 80px;
          height: 80px;
          position: relative;
          top: 0px;
          left: 18px;
        }
      }

      .card_name {
        font-size: 20px;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #fff;
        line-height: 35px;
        text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
      }
    }
  }

  .other_player {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .overlay_play_mulity {
      position: absolute;
      height: calc(25vh);
      width: calc(25vh / 2);

      .freeze_bg {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/board/freeze.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #fff;
        line-height: 0px;
        text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
        font-size: 20px;
        line-height: 70px;
      }

      .acc_bg {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/board/accelerate.png");
        background-repeat: no-repeat;
        background-size: 50% 50%;
        background-position: center;
      }
    }

    .item {
      width: 32vh;
      height: 60vh;
      font-size: 36px;
      cursor: pointer;

      .overlay_play {
        position: absolute;
        height: calc(50vh);
        width: calc(50vh / 2);

        .freeze_bg {
          width: 100%;
          height: 100%;
          background-image: url("../../assets/board/freeze.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-family: "Noto Sans";
          font-weight: normal;
          color: #fff;
          line-height: 0px;
          text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
          font-size: 30px;
          line-height: 70px;
        }

        .acc_bg {
          width: 100%;
          height: 100%;
          background-image: url("../../assets/board/accelerate.png");
          background-repeat: no-repeat;
          background-size: 50% 50%;
          background-position: center;
        }
      }
    }

    .other_players {
      width: calc(34vh + 30px);
      height: calc(60vh + 12px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px 30px;

      .item {
        width: 17vh;
        height: 30vh;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .level_panel {
    width: 310px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .level_text {
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }

    .cube {
      width: 77px;
      height: 80px;
    }
  }

  .left_panel {
    width: 310px;
    height: 786px;
    background-image: url("../../assets/icon/left_panel.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .avatar_box {
      width: 200px;
      height: 200px;
      background-image: url("../../assets/icon/avatar_panel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .fake {
        width: 180px;
        height: 180px;
        border-radius: 50%;
      }
    }
  }
}

.right_panel {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .opt_panel {
    width: 38vh;
    height: 70vh;
    background-image: url("../../assets/icon/opt_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .score {
      width: 32vh;
      height: 24vh;
      background-image: url("../../assets/icon/score_panel.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3vh;

      .next {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 3vh;
        // color: #fff;
        font-weight: bold;
      }
    }

    .panel_vs {
      width: 90%;
      height: 43vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .vs_opponent {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 14vh;

        .avatar {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          font-size: 2.3vh;
          font-family: "Noto Sans";
          font-weight: normal;
          color: #e2f4ff;

          .username {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: center;
          }

          .avatar_box {
            width: 14vh;
            height: 14vh;
            background-image: url("../../assets/icon/avatar_panel.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .fake {
              width: 13vh;
              height: 13vh;
              // margin-top: 3vh;
              // margin-left: 0.8vh;
              border-radius: 50%;
            }
          }
        }

        .tips_box {
          width: 14vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1vh;

          .tips_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            font-size: 3vh;

            .icon {
              width: 1.6vh;
              height: 2.3vh;
              margin-left: 1vh;
              margin-right: 1vh;
            }
          }

          .status {
            width: 14vh;
            height: 7vh;
            background-image: url("../../assets/icon/status_bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            font-size: 36px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #e2f4ff;

            .pause {
              width: 5vh;
              height: 6vh;
            }
          }
        }
      }

      .vs {
        height: 32vh;
        width: 4.4vh;
        position: absolute;
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;

        .img {
          width: 100%;
          height: 14vh;
          display: flex;
          align-items: center;
          justify-content: center;

          .item {
            width: 4.4vh;
            height: 4.2vh;
            background-image: url("../../assets/icon/VS.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .tips_box {
      width: 230px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;

      .tips_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 30px;

        .icon {
          width: 16px;
          height: 24px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .data {
        width: 230px;
        height: 55px;
        background-image: url("../../assets/icon/score_btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #fff;
        margin-top: 5px;
      }

      .status {
        width: 162px;
        height: 95px;
        background-image: url("../../assets/icon/status_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-top: 5px;

        .pause {
          width: 51px;
          height: 59px;
        }
      }
    }
  }

  .other_player {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    justify-content: space-between;

    .overlay_play_mulity {
      position: absolute;
      height: calc(25vh);
      width: calc(25vh / 2);

      .freeze_bg {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/board/freeze.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #fff;
        line-height: 0px;
        text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
        font-size: 20px;
        line-height: 70px;
      }

      .acc_bg {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/board/accelerate.png");
        background-repeat: no-repeat;
        background-size: 50% 50%;
        background-position: center;
      }
    }

    .item {
      width: 17vh;
      height: 30vh;
      font-size: 20px;
      cursor: pointer;
      margin-top: 4vh;
    }

    .opt_panel {
      width: 20vh;
      height: 34vh;
      background-image: url("../../assets/icon/opt_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      right: 1vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .score {
        width: 16vh;
        height: 12vh;
        background-image: url("../../assets/icon/score_panel.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-top: 11px;

        .next {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 2.3vh;
          font-weight: bold;
        }
      }

      .tips_box {
        width: 16vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tips_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          font-size: 2.3vh;

          .icon {
            width: 1vh;
            height: 1.6vh;
            margin-left: 1vh;
            margin-right: 1vh;
          }
        }

        .data {
          width: 16vh;
          height: 3.5vh;
          background-image: url("../../assets/icon/score_btn.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.3vh;
          color: #fff;
          // margin-top: 5px;
        }
      }
    }
  }

  .arrow_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    background: linear-gradient(180deg, #6062aa 0%, #5f89ab 100%);
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 3px 3px #ffffff26 inset;
    width: 38vh;
    height: 28vh;
    border-radius: 30px;
    font-family: "Noto Sans";

    .user_custom_key {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .keys_box {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .keys_container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .all_key {
            width: 48px;
            height: 48px;
            border-radius: 15px;
            border: 1px solid #ffffff;
            box-shadow: 0px 4px 4px 0px #00000040;
            background: #607fab;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 25px;
          }

          .keys_text {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            margin-top: 5px;
          }

          .space_key {
            width: 87%;
          }
        }
      }
    }
  }

  .viewer_button_list {
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .button_common {
      height: 60px;
      font-size: 24px;
    }
  }
}

.chat {
  display: flex;
  flex: 4 1 0%;
  padding: 10px;
  margin: 5px;
  // background-color: #bccdd6b3;
  border-radius: 2px;
  min-height: 66vh;
  max-height: 73vh;
  overflow: scroll;
}

.chat::-webkit-scrollbar {
  display: none;
}

.game {
  border-radius: 2px 2px 2px 2px;
}

.left-chat {
  border-radius: 2px 2px 2px 60px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.next-p {
  font-size: 3vh;
  margin-bottom: 30px;
  position: relative;
}

.score-level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.score-level-div {
  height: 9vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: ;
}

.score-p {
  font-size: 2vh;
  margin-bottom: 10px;
  position: relative;
}

.mode-select {
  background: none;
  border: 2px solid white;
  width: 10vh;
  color: white;
  height: 40px;
  border-radius: 4px;
  font-family: "ArcadeClassic";
}

.score-num {
  color: white;
  font-size: 3vh;
}

.mode-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(0, 0, 0, 0.3);
  // display: flex;
  // flex-direction: column;
  // height: 86vh;
  // width: 43vh;
  // border: 3px solid white;
  // border-radius: 3px 0px 0px 3px;
}

.spectator_top {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
  width: 44vh;
  height: 11vh;
  padding: 0 2vh;
  line-height: 11vh;
  background: url("../../assets/icon/spectator_top.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0.5vh 0.5vh 0 0;
  font-size: 3vh;
  font-family: "Noto Sans";
  font-weight: normal;
  color: #e2f4ff;
  text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
  -webkit-text-stroke: 1px #1b2b58;
  text-stroke: 1px #1b2b58;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row {
  flex: 1 1 0%;
  display: flex;
}

.col {
  flex: 1 1 10%;
  border: 1px solid #b7a5a5;
}

.chat-field {
  display: flex;
  flex: 1 1 0%;
  border: 3px solid white;
  border-radius: 5px;
  height: 0px;
  min-height: 100%;
}

.players {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background-color: rgba(0, 0, 0, 0.3);
  border-right: 3px solid white;
}

.messages {
  flex: 4 1 0%;
  display: flex;
  flex-direction: column;
}

.title-PM {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  border-bottom: 3px solid white;
}

.input-message {
  height: 4vh;
  padding: 0.5vh 0.5vh 0.5vh 1vh;
  border-radius: 5px;
  border: 2px solid white;
  width: 100%;
  background: none;
  outline: none;
  color: white;
  font-family: Verdana;
  font-size: 1.5vh;
  font-family: "ArcadeClassic";
  padding-right: 2.6vh;
}

.input-form {
  display: flex;
  padding: 5px;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}

.messages-field {
  display: block;
  flex: 1 1 0%;
  padding: 5px;
  flex-wrap: wrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

.messages-field::-webkit-scrollbar {
  display: none;
}

.chatsender {
  color: #7a2d58;
  font-size: large;
}

.chatmsg {
  color: white;
  width: 0px;
  width: 0;
  min-width: 100%;
}

.send-icon {
  position: absolute;
  color: #6c6b6b;
  margin-right: 8px;
  transition-delay: 5ms;
  transition: all 1s;
}

.icon {
  font-size: unset;
  transition-delay: 5ms;
  transition: all 1s;
}

.icon:hover {
  font-size: 1.3em;
  color: #7a2d58;
  transition: all 1s;
}

.send-icon:hover {
  cursor: pointer;
  transition: all 1s;
}

.name-players {
  display: flex;
  flex-direction: column;
  // flex: 1 1 0%;
  align-items: center;
  padding: 5px;
  overflow: scroll;
  color: white;
}

.name-players::-webkit-scrollbar {
  display: none;
}

.player-room {
  color: #109910;
  font-size: large;
}

.player-left {
  color: #cb3535;
  font-size: large;
}

.player-admin {
  color: #dfda0c;
  font-size: large;
}

.overlay {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border: 3px solid white;
  // border-radius: 5px;
  // background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  // top: 10px;
  // left: -3px;
  // height: 86vh;
  // width: 43vh;
  height: calc(86vh);
  width: calc(86vh / 2);
  // left: 29px;

  .freeze_bg {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/board/freeze.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Noto Sans";
    font-weight: normal;
    color: #fff;
    line-height: 35px;
    text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
    font-size: 50px;
    line-height: 70px;
  }

  .acc_bg {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/board/accelerate.png");
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center;
  }
}

.overlay-content {
  width: 70%;
  color: white;
}

.overlay-content div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.key {
  font-size: 2vh;
  border-radius: 3px;
  padding: 1px 2px 0px;
  border: 3px solid white;
}

.start-game {
  margin-top: 40px;
  font-size: 3vh;
  color: white;
}

.list-item {
  color: white;
  height: 5%;
  margin-bottom: 1px;
  margin-top: 1px;
  // flex-grow: 1;
  display: flex;
  background-color: rgba(255, 255, 255, 0.28);
  align-items: center;
}

.list-item:hover {
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 1.8vh;
  transition: all 0.8s;
}

.content-list-item {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.content-list-item:nth-child(1) {
  display: flex;
  justify-content: center;
  flex: 3 1;
}

.game-over {
  font-size: 5vh;
  color: white;
}

.players-overlay {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border: 3px solid white;
  // border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 19px;
  height: 30vh;
  width: 15vh;
  left: 3px;
}

.Toastify__toast {
  font-family: "ArcadeClassic";
}

.players-field {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 600px;
  // max-height: 800px;
  // overflow: scroll;
}

.players-field::-webkit-scrollbar {
  display: none;
}

.home-button {
  color: white;
  border: 0px;
  outline: none;
  opacity: 0.5;
  transition: opacity 0.2s ease 0s;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  font-size: 3vh;
  margin-left: 20px;
  font-family: "ArcadeClassic";
  cursor: pointer;
}

.home-button:hover {
  opacity: 1;
  transition: opacity 0.2s ease 0s;
}

.sound-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sound-icon {
  font-size: 1.3em;
  cursor: pointer;
  color: white;
}

// :root{
//   --toastify-font-family: ArcadeClassic;
// }

@media only screen and (max-width: 1230px) {
  // .room-field{
  //   flex-direction: column;
  //   overflow: scroll;
  // }

  // .room-field::-webkit-scrollbar {
  //   display: none;
  // }

  .right-field {
    display: none;
  }
}
