.choose-game-type {
    min-width: 1026px;
    min-height: 535px;
    display: flex;
    justify-content: center;

    .game_type {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 858px;
        gap: 100px;
        .type51,
        .type52,
        .type53,
        .type54,
        .type55 {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            cursor: pointer;
            border-radius: 30px;
            width: 270px;
            box-shadow: 5px 5px 0px 0px #4e1a90;

            .text {
                width: 271px;
                height: 63px;
                flex-shrink: 0;
                border-radius: 0px 0px 30px 30px;
                background: #cba7f4;
                color: #40077e;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 63px;
            }
        }
        .type51:hover,
        .type52:hover,
        .type53:hover,
        .type54:hover,
        .type55:hover {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
        .type51 {
            height: 481px;
            position: absolute;
            background: url(../../assets/hall/selection1_bg.png) lightgray -374.48px 0px / 260.37% 100% no-repeat;
            left: 0;
            top: 0;
        }
        .type52 {
            height: 229px;
            background: url(../../assets/hall/selection21_bg.png) lightgray 50% / cover no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 293px;
            top: 0;
        }
        .type53 {
            height: 229px;
            background: url(../../assets/hall/selection22_bg.png) lightgray 50% / cover no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 252px;
            left: 293px;
        }
        .type54 {
            height: 229px;
            background: url(../../assets/hall/selection31_bg.png) lightgray 50% / cover no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 586px;
            top: 0;
        }
        .type55 {
            height: 229px;
            background: url(../../assets/hall/selection32_bg.png) lightgray 50% / cover no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 586px;
            top: 252px;
        }

        .battle_div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            width: 100%;
            height: 100%;
            margin-bottom: 52px;

            .top {
                width: 470px;
                height: 430px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 44px;
                position: relative;
                border-radius: 20px;
                box-shadow: 5px 5px 25px 10px rgba(60, 43, 74, 0.3);

                .ticker-type {
                    position: absolute;
                    top: -28px;
                    width: 298px;
                    height: 53px;
                    line-height: 53px;
                    text-align: center;
                    border-radius: 33px;
                    border: 2px solid #fff;
                    background: #904ad4;
                    color: #fff;
                    font-size: 24px;
                    font-family: "Noto Sans";
                    font-weight: 700;
                }
            }

            .top1 {
                background: url("../../assets/hall/selection13_bg.png");
                background-size: cover;
            }

            .top2 {
                background: url("../../assets/hall/selection23_bg.png");
                background-size: cover;
            }
            .button_common {
                height: 65px;
                font-size: 24px;
            }
        }
    }
    .spin_div1 {
        width: 100%;
        height: 40px;
        text-align: center;
        background-color: rgba(90, 90, 90, 0);
    }
}

.choose_game_div {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
