.home-wrapper {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 100%;
    height: 100%;
    position: relative;

    .sideshow {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .box_container {
            position: relative;
            width: 100%;
            height: 368px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mode-container {
        margin-bottom: 76px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        gap: 64px;

        .mode {
            position: relative;
            min-width: 293px;
            width: auto;
            height: 107px;
            border-radius: 20px;
            background: linear-gradient(180deg, #e3e3e3 0%, #d3b2ff 100%);
            box-shadow: 6px 6px 0px 0px #8582c7;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            .text {
                color: #40077e;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 35px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
            .wait {
                position: absolute;
                top: -20px;
                right: -40px;
                display: inline-flex;
                height: 40px;
                padding: 0px 15px;
                align-items: center;
                flex-shrink: 0;
                border-radius: 15px;
                background: linear-gradient(183deg, #bfb7ed 2.81%, #b8b8be 97.41%);
                box-shadow: 0px 5px 0px 0px #7a67b4;
                color: #555;
                font-family: "Noto Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                .ant-statistic .ant-statistic-content {
                    margin-left: 3px;
                    margin-right: 3px;
                    color: #555;
                    font-family: "Noto Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .wait_ic {
                    margin-right: 5px;
                }
            }
        }

        .mode:hover {
            transform: scale(1.1);
            box-sizing: border-box;
            .text {
                font-size: 40px;
            }
        }

        .mode_dis {
            border-radius: 20px;
            background: linear-gradient(180deg, #e3e3e3 0%, #aeadaf 100%);
            box-shadow: 6px 6px 0px 0px #8582c7;
            .text {
                color: #4b484f;
            }
        }

        .mode_dis:hover {
            transform: scale(1);
            cursor: no-drop;
            .text {
                font-size: 35px;
            }
        }
    }
    .wait_ic {
        background: url("../../assets/hall/wait_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
