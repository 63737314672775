.room_list {
    display: flex;
    flex-direction: column;
    width: 1589px;
    height: 838px;

    .room_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        height: 122px;
        width: 100%;
        padding: 0 80px;

        button {
            box-sizing: border-box;
            height: 50px;
            padding: 9px 24px;
            margin: 0;
            line-height: 30px;
            font-size: 30px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #32464f;
            background-color: rgba(239, 250, 255, 0.9);
            border-radius: 23.5px;
            border: none;
            cursor: pointer;
        }

        .right {
            display: flex;
            gap: 20px;
        }
    }

    .room_list_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        height: 650px;
        position: relative;

        .spin {
            background-color: #ffffff1e;
            width: 90%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            width: 1460px;
            height: 196px;
            padding: 30px;
            background: url("../../assets/hall/room_list_item_bg.png") 100% no-repeat;
            background-size: 100% 100%;

            .user_msg {
                display: flex;
                gap: 20px;
                align-items: center;

                .avatar {
                    width: 130px;
                    height: 130px;
                    border-radius: 50%;
                    overflow: hidden;
                }

                .username {
                    font-size: 36px;
                    line-height: 50px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #32464f;
                }
            }

            .capacity {
                font-size: 36px;
                line-height: 50px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #32464f;
            }

            .button_list {
                display: flex;
                gap: 48px;

                button {
                    min-width: 147px;
                    height: 57px;
                    font-size: 36px;
                    padding: 0 5px;
                    margin: 0;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #ffffff;
                    border: none;
                    line-height: 57px;
                    cursor: pointer;
                }

                .button_spectator {
                    background: url("../../assets/hall/room_list_bt_spectator.png") 100% no-repeat;
                    background-size: 100% 100%;
                }

                .button_join {
                    background: url("../../assets/hall/room_list_bt_join.png") 100% no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .page_button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            gap: 40px;
            button {
                width: 41px;
                height: 35px;
                border: none;
                cursor: pointer;
            }
            .pre {
                background: url("../../assets/hall/ic_pre_page.png") 100% no-repeat;
                background-size: 100% 100%;
            }
            .next {
                background: url("../../assets/hall/ic_next_page.png") 100% no-repeat;
                background-size: 100% 100%;
            }
            .page {
                font-size: 30px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #24557d;
            }
            .disabled {
                cursor: not-allowed;
            }
        }
    }
}
