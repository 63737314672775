#market {
    width: 1690px;
    height: 800px;
    display: flex;
    flex-direction: column;

    .filter_header {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter_right,
        .filter_left {
            display: flex;
            gap: 50px;
            align-items: center;
        }

        .dropdown {
            background: none;
            box-shadow: none;
            font-weight: 500;
        }
    }

    .content {
        height: calc(800px - 85px - 40px - 16px - 8px);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 40px;
        margin-top: 16px;

        .filter_label {
            width: 160px;
            min-width: 160px;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .label_item {
                width: 100%;
                border-bottom: 1px solid #40077e;
                padding-bottom: 16px;

                .results {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .filter_title {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 8px;
                }

                .filter_rarity {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    .rarity_item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #641cb2;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        height: 22px;
                        cursor: pointer;
                    }
                }

                .filter_validity {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    color: #641cb2;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    line-height: 22px;
                    font-weight: 500;
                    height: 22px;

                    .ant-input-number .ant-input-number-handler-wrap {
                        display: none;
                    }

                    .ant-input-number,
                    .ant-input-number .ant-input-number-input {
                        width: 50px;
                        padding: 0;
                        border-radius: 0;
                        color: #641cb2;
                        text-align: center;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 500;
                        height: 22px;
                        background-color: transparent;
                        border: none;
                        border-bottom: #40077e 1px solid;
                        box-shadow: none;
                    }
                }

                .validity_err {
                    padding: 10px 0;
                    color: #c61e1e;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
        .content_list {
            width: 100%;
            height: calc(100% - 8px);
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            overflow-y: auto;
            margin-top: 8px;

            .loading {
                height: 200px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .nft_list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 16px 15px;

                .nft {
                    width: 200px;
                    height: 313px;
                    background-color: #fff;
                    border-radius: 8px;
                    cursor: pointer;

                    .nft_img {
                        width: 200px;
                        height: 200px;
                        border-radius: 8px 8px 0 0;
                        border-width: 0px;
                    }

                    .nft_detail {
                        margin: 5px 0;
                        color: #6e41d1;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        line-height: 25px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        .nft_title {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            height: 25px;
                            padding: 0 10px;
                        }

                        .nft_index {
                            color: #40077e;
                            font-size: 21px;
                            font-weight: 700;
                            line-height: 29px;
                            height: 29px;
                            padding: 0 10px;
                        }

                        .nft_price {
                            display: flex;
                            gap: 6px;
                            align-items: center;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 0 10px;
                            .price_usd {
                                font-size: 10px;
                                font-weight: 400;
                            }
                        }
                        .nft_buy {
                            padding: 0 10px;
                            display: none;
                            .tags_common {
                                height: 28px;
                                font-size: 14px;
                                min-width: 60px;
                            }
                        }
                        .nft_type {
                            border-width: 1px;
                            margin-top: 8px;
                            margin-bottom: 8px;
                        }
                    }
                }

                .nft:hover {
                    .nft_detail {
                        .nft_price {
                            display: none;
                        }

                        .nft_buy {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        bottom: 20px;
        height: 65px;
        .button_common {
            font-size: 24px;
            height: 65px;
        }
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 18px;
        gap: 10px;
        border-radius: 20px;
        background: linear-gradient(180deg, #fff 0%, #e8dbfb 100%);
        box-shadow: 4px 4px 0px 0px #8582c7;
        color: #40077e;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 37px;
        width: auto;
        min-width: auto;
        box-sizing: border-box;
        border: none;
    }
    .button:hover {
        border: none !important;
    }
}

.refresh_ic {
    width: 24px;
    height: 24px;
    background: url("../../assets/public_icon/refresh_ic.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.dropdown_black_ic {
    width: 24px;
    height: 16px;
    background: url("../../assets/market/dropdown_black_ic.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.success_ic {
    width: 50px;
    height: 50px;
    background: url("../../assets/market/success_ic.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.filed_ic {
    width: 50px;
    height: 50px;
    background: url("../../assets/market/filed_ic.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.clear_ic {
    width: 68px;
    height: 68px;
    background: url("../../assets/market/clear.ic.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
