.invite_cashback {
    display: flex;
    flex-direction: column;
    width: 1367px;

    .invite_top,
    .invite_center,
    .invite_bottom {
        border-radius: 13px;
        background: rgba(255, 255, 255, 0.3);
        margin-bottom: 26px;
    }

    .invite_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 38px 0 24px;
        height: 248px;
        box-sizing: border-box;

        .invite_title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .main_title {
                color: #40077e;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 37px;
                white-space: pre-line;
            }

            .subtitle {
                display: flex;
                align-items: center;
                height: 50px;

                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 21px;
                font-weight: 600;
                gap: 11px;
                cursor: pointer;
            }
        }

        .invite_content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .invite_item {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item_content {
                    width: 563px;
                    height: 71px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 29px;
                    box-sizing: border-box;
                    border-radius: 16px;
                    border: 1px solid rgba(255, 255, 255, 0.15);
                    background: #40077e;

                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-weight: 500;

                    .item_item {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 460px;
                    }
                }

                .item_title {
                    color: #2a005e;
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }

    .invite_center {
        // height: 151px;
        display: flex;
        gap: 140px;
        padding: 12px;
        box-sizing: border-box;

        .step {
            display: flex;
            gap: 28px;

            .step1_icon {
                background-image: url("../../../assets/hall/level1.png");
                background-size: cover;
                width: 47.055px;
                height: 95.303px;
            }
            .step2_icon {
                background-image: url("../../../assets/hall/level2.png");
                background-size: cover;
                width: 47.063px;
                height: 95.303px;
            }
            .step3_icon {
                background-image: url("../../../assets/hall/level3.png");
                background-size: cover;
                width: 47.15px;
                height: 95.303px;
            }

            .step_content {
                display: flex;
                flex-direction: column;
                gap: 17px;

                .content_title {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .content_subtitle {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    white-space: pre-line;
                }
            }
        }

        .step1_icon {
            background-image: url("../../../assets/hall/level1.png");
            background-size: cover;
        }
        .step2_icon {
            background-image: url("../../../assets/hall/level2.png");
            background-size: cover;
        }
    }

    .invite_bottom {
        display: flex;
        padding: 24px 0 0 0;
        box-sizing: border-box;
        justify-content: space-between;

        .invite_overview {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0 24px;
            box-sizing: border-box;

            .overview_title {
                color: #40077e;
                height: 50px;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .overview_content {
                display: flex;
                flex-direction: column;
                width: 220px;
                gap: 40px;

                .overview_item {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    width: 220px;

                    .item_content {
                        display: flex;
                        align-items: center;
                        width: 200px;
                        height: 70px;
                        padding: 0px 29px;
                        box-sizing: border-box;

                        border-radius: 16px;
                        border: 1px solid rgba(255, 255, 255, 0.15);
                        background: #40077e;

                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .item_title {
                        color: #2a005e;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }
        }

        .invitees {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0 24px;
            box-sizing: border-box;
            width: 1100px;
            height: 400px;

            .invitees_pagination {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 60px;
                border-top: 1px solid #40077e;
                border-bottom: 1px solid #40077e;
            }
        }
    }
}
