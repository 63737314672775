.market_detail {
    .nft_detail {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 48px;
        padding-bottom: 55px;
        width: 1367px;
        min-height: 470px;

        .nft_img {
            width: 470px;
            height: 470px;
            border-radius: 13px;
        }

        .nft_left {
            width: 849px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            .nft_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #4f1a90;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .title_left {
                    display: flex;
                    gap: 36px;
                    align-items: center;
                }

                .title_right {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .ant-statistic-content {
                        display: flex;
                        align-items: center;
                        color: #4f1a90;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .series {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }

                .nft_type {
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .nft_price {
                display: flex;
                gap: 16px;
                flex-direction: column;
                width: 100%;
                border-radius: 13px;
                background: rgba(255, 255, 255, 0.3);
                min-height: 140px;
                padding: 10px 16px;
                box-sizing: border-box;

                .tit {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 21px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .ant-statistic-content {
                        display: flex;
                        align-items: center;
                        color: #40077e;
                        font-family: "Noto Sans";
                        font-size: 21px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .action,
                .price {
                    display: flex;
                    align-items: center;
                    gap: 32px;
                    height: calc(100% - 50px);

                    .tags_common {
                        font-size: 18px;
                        height: 50px;
                        min-width: 140px !important;
                    }
                }
                .price {
                    justify-content: space-between;

                    .icon {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .price_ic {
                            width: 70px;
                            height: 70px;
                        }
                        .number {
                            color: #40077e;
                            font-family: "Noto Sans";
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            display: flex;
                            flex-direction: column;
                            .usd {
                                color: #40077e;
                                font-family: "Noto Sans";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }
                }
            }

            .nft_info {
                width: 100%;
                border-radius: 13px;
                background: rgba(255, 255, 255, 0.3);
                padding: 10px 16px;
                box-sizing: border-box;

                .info_item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    .label {
                        font-size: 20px;
                        font-weight: 700;
                    }
                    a {
                        color: #40077e;
                        text-decoration-color: #40077e;
                    }
                    .nft_type {
                        border-width: 0;
                    }
                }

                .tit {
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
