.dialog_small_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 56px;
    .msg {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 500px;
        min-height: 400px;
        color: #40077e;
        font-family: "Noto Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        gap: 130px;

        :global .button_common {
            height: 60px;
            font-size: 20px;
        }
    }
}
