.mint-home-wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    .mint-home {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 40px;
        margin-bottom: 52px;

        .left-left {
            // left: 34px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 420px;
            margin-bottom: 55px;
            box-sizing: border-box;
            margin-top: 50px;

            .banner {
                width: 420px;
                height: 420px;
                background: #9f9feb;
                border-radius: 20px;
                overflow: hidden;
                box-shadow: 5px 5px 25px 10px rgba(60, 43, 74, 0.3);

                .slick-dots li button:before,
                .slick-dots li.slick-active button:before {
                    display: none;
                }

                .ant-carousel .slick-dots li.slick-active button {
                    background-color: #936eeb;
                }

                .ant-carousel .slick-slide {
                    text-align: center;
                    height: 420px;
                    overflow: hidden;
                }

                .ant-carousel .slick-dots li button {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }
        }

        .top {
            background-image: url("../../../assets/hall/ticker_bg.png");
            background-size: cover;
            width: 430px;
            height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 10px;
            margin-top: 44px;
            position: relative;
            border-radius: 20px;
            box-shadow: 5px 5px 25px 10px rgba(60, 43, 74, 0.3);

            .ticker-type {
                position: absolute;
                top: -28px;
                width: 298px;
                height: 53px;
                line-height: 53px;
                text-align: center;
                border-radius: 33px;
                border: 2px solid #fff;
                background: #904ad4;
                color: #fff;
                font-size: 24px;
                font-family: "Noto Sans";
                font-weight: 700;
            }

            .ticker-img {
                background-image: url("../../../assets/hall/nft_demo.png");
                background-size: cover;
                width: 230px;
                height: 230px;
                margin-top: 44px;
            }

            .ticker-img-free {
                background-image: url("../../../assets/hall/nft_demo.png");
                background-size: cover;
                width: 300px;
                height: 300px;
                margin-top: 44px;
            }

            .total-div {
                position: absolute;
                bottom: 0;
                width: 100%;
                border-radius: 0 0 20px 20px;
                background: linear-gradient(99deg, #904ad4 31.84%, #40077e 167.75%);
                box-shadow: -5px -5px 5px 0px rgba(60, 43, 74, 0.3);
                height: 120px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;

                .price_div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .discount_total {
                    color: #fff;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: normal;
                }

                .total {
                    color: #fff;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: line-through;
                }

                .option {
                    display: flex;
                    gap: 60px;
                    justify-content: center;
                    align-items: center;
                    .count {
                        color: #fff;
                        text-align: center;
                        font-family: "Noto Sans";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                    }
                    .arrow_button {
                        height: 42px;
                    }
                }
            }
        }

        .button_common {
            font-size: 24px;
            height: 65px;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 32px;
            margin-top: 50px;
        }

        .buy_vsys {
            position: absolute;
            left: 50px;
            top: 50px;
            background: radial-gradient(
                    76.16% 76.16% at 31.97% 19.67%,
                    rgba(255, 255, 255, 0.7) 0%,
                    rgba(255, 255, 255, 0) 69.79%,
                    rgba(255, 255, 255, 0) 100%
                ),
                #df9800 !important;
            height: 65px;
            font-size: 24px;
        }
    }

    .mint-free-nft {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        min-width: 500px;
        min-height: 300px;
        padding-bottom: 30px;

        .spindiv {
            width: 100%;
            height: 40px;
            text-align: center;
        }

        .nft-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            min-height: 300px;
            max-width: 1400px;

            .nft-item {
                margin: 24px 50px 0 50px;
                width: 160px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .nft-img {
                    width: 160px;
                    height: 160px;
                    background: #64a4e3;
                    border-radius: 16px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                .nft-id {
                    font-size: 18px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #24557d;
                    line-height: 20px;
                }

                .nft-color {
                    font-size: 18px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #ed7f25;
                    line-height: 24px;
                }
            }
        }
    }
    .bottom-option {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 60px;

        .button_common {
            font-size: 24px;
            height: 65px;
            flex-direction: column;
            line-height: 20px;

            .span {
                display: flex;
                align-items: center;
                gap: 5px;
                text-shadow: none;
            }

            .button_span_discount {
                font-size: 12px;
                font-weight: 100;
                line-height: 10px;
            }

            .button_span {
                font-size: 12px;
                font-weight: 100;
                line-height: 10px;
                text-decoration: line-through;
            }
        }
    }
}
