.backMovement {
    // width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    .loader {
        text-align: center;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .loader span {
        width: 58px;
        height: 58px;
        border-radius: 8px;
        background: rgba(97, 98, 179, 0);
        -webkit-animation: loader 10s infinite linear;
        -moz-animation: loader 10s infinite linear;
        animation: loader 10s infinite linear;
        // transform: ;
    }
    // 5,10,15
    .loader span:nth-child(5n + 5) {
        -webkit-animation-delay: 1.3s;
        -moz-animation-delay: 1.3s;
        animation-delay: 1.3s;
    }
    // 2,5,8,11
    .loader span:nth-child(7n + 2) {
        -webkit-animation-delay: 1.7s;
        -moz-animation-delay: 1.7s;
        animation-delay: 1.7;
    }
    // 5,7,9,11
    .loader span:nth-child(2n + 5) {
        -webkit-animation-delay: 2.7s;
        -moz-animation-delay: 2.7s;
        animation-delay: 2.7s;
    }
    // 10,13,16
    .loader span:nth-child(3n + 10) {
        -webkit-animation-delay: 3.7s;
        -moz-animation-delay: 3.7s;
        animation-delay: 3.7s;
    }
    // 2,9,16
    .loader span:nth-child(3n + 2) {
        -webkit-animation-delay: 6.5s;
        -moz-animation-delay: 6.5s;
        animation-delay: 6.5s;
    }
    // 5,9,13
    .loader span:nth-child(4n + 5) {
        -webkit-animation-delay: 9.5s;
        -moz-animation-delay: 9.5s;
        animation-delay: 9.5s;
    }
    // 7,10,13
    .loader span:nth-child(6n + 3) {
        -webkit-animation-delay: 13s;
        -moz-animation-delay: 13s;
        animation-delay: 13s;
    }

    @keyframes loader {
        0% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(0, 0vh) rotateZ(0deg);
        }
        20% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.5);
            -webkit-transform: translate(2vw, 20vh) rotateZ(90deg);
        }
        40% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 1);
            -webkit-transform: translate(4vw, 40vh) rotateZ(144deg);
        }
        60% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(6vw, 60vh) rotateZ(216deg);
        }
        80% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(8vw, 80vh) rotateZ(288deg);
        }
        100% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0);
            -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
        }
    }

    @-webkit-keyframes loader {
        0% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(0, 0vh) rotateZ(0deg);
        }
        20% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.5);
            -webkit-transform: translate(2vw, 20vh) rotateZ(90deg);
        }
        40% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 1);
            -webkit-transform: translate(4vw, 40vh) rotateZ(144deg);
        }
        60% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(6vw, 60vh) rotateZ(216deg);
        }
        80% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(8vw, 80vh) rotateZ(288deg);
        }
        100% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0);
            -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
        }
    }

    @-moz-keyframes loader {
        0% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(0, 0vh) rotateZ(0deg);
        }
        20% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.5);
            -webkit-transform: translate(2vw, 20vh) rotateZ(90deg);
        }
        40% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 1);
            -webkit-transform: translate(4vw, 40vh) rotateZ(144deg);
        }
        60% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(6vw, 60vh) rotateZ(216deg);
        }
        80% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0.3);
            -webkit-transform: translate(8vw, 80vh) rotateZ(288deg);
        }
        100% {
            width: 58px;
            height: 58px;
            background: rgba(97, 98, 179, 0);
            -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
        }
    }
}
