.FlipClock {
    text-align: center;
    height: 80px;
}

.FlipClock .M-Flipper {
    margin: 0 5px;
}

.FlipClock em {
    display: inline-block;
    line-height: 80px;
    font-size: 40px;
    font-style: normal;
    vertical-align: top;
    color: #fff;
    margin: 0 5px;
}
