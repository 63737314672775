.table_no_data {
    position: relative;
    display: flex;
    justify-content: center;

    :global .ant-image {
        width: 200px;
        aspect-ratio: 1 / 1;
    }

    .title {
        color: #40077e;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        position: absolute;
        bottom: 0;
    }
}
