#common_table {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90px;

    :global {
        .ant-table {
            border-radius: 0;
            background: transparent;
            border-color: transparent;
            .ant-table-placeholder,
            .ant-table-placeholder:hover > td {
                background: transparent;
                background-color: transparent;
            }
        }

        .ant-table tr th {
            background: transparent;
            border-color: transparent;
            cursor: pointer;
        }

        .ant-table-thead tr th {
            background: transparent;
            padding: 0px 8px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #2a005e;
            font-size: 21px;
            font-weight: 600;
            line-height: 45px;
            height: 45px;
        }

        .ant-table-thead tr th::before {
            display: none;
        }

        .ant-table-tbody tr td {
            background: transparent;
            padding: 0px 8px;
            font-family: "Noto Sans";
            border: none;
            color: #4f1a90;
            font-size: 21px;
            font-weight: 400;
            cursor: pointer;
            line-height: 45px;
            height: 45px;
        }
        .nft_right {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            padding: 8px 0;

            .nft_img {
                width: 50px;
                height: 50px;
                border-radius: 8px;
            }

            .nft_series {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                color: #40077e;
                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                .series {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: #6e41d1;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        .tags_common{
            height: 40px;
            font-size: 16px;
            width: 100%;
        }
    }
}
