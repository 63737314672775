.safe_settings_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .step1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 42px 0px 0px 0px;

        .top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .center {
                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .button {
                border-radius: 20px;
                background: linear-gradient(178deg, #a66fdd 5.68%, #6b1bbb 98.16%);
                box-shadow: 3px 3px 0px 0px #541c9b;

                display: inline-flex;
                padding: 10px 28px;
                justify-content: center;
                align-items: center;
                gap: 10px;

                color: #fff;
                font-family: "Noto Sans";
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
            }
        }
        .safe_img {
            width: 500px;
            height: 195.409px;
            flex-shrink: 0;
            margin-top: 138px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .step2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            display: flex;
            align-items: center;
            padding: 20px 36px 10px 36px;
            gap: 18px;
            width: 100%;

            :global {
                .close_button {
                    width: 32px;
                    height: 32px;
                }
            }

            .title {
                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 563px;
            height: 560px;
            gap: 20px;

            .err_info {
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: rgba(218, 20, 20, 0.72);
                width: 100%;
            }
            .msg_info {
                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: 100%;
            }
            .msg_info_err {
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: rgba(218, 20, 20, 0.72);
                width: 100%;
            }

            .code {
                color: #2a005e;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .code_send {
                color: #2a005e;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .inputStyle {
                width: 71px !important;
                height: 71px;
                margin: 0 10px;
                font-size: 36px;
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #40077e;
                color: #fff;
            }

            :global .button_common {
                height: 65px;
                font-size: 24px;
            }

            .option {
                margin-top: 64px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .option1 {
                margin-top: 64px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
