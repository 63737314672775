.rank-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 1712px;
    height: 700px;
    box-sizing: border-box;

    .tab-div {
        display: flex;
        align-items: center;
        gap: 52px;

        .tag {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 10px 28px;
            cursor: pointer;
            font-size: 22px;
            font-family: "Noto Sans";
            background: linear-gradient(178deg, #989898 5.68%, #e3e2e4 98.16%);
            box-shadow: 3px 3px 0px 0px #a09fa0;
            color: #4f1a90;
            border: none;
        }

        .check {
            background: linear-gradient(178deg, #4f1a90 5.68%, #8428f4 98.16%);
            box-shadow: 3px 3px 0px 0px #541c9b;
            color: #fff;
        }
    }

    .table_bg {
        .filter_div {
            height: 68px;
            z-index: 100;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 32px 0 16px 0;
            border-top: 1px solid #4f1a90;
            border-bottom: 1px solid #4f1a90;

            .filters {
                display: flex;
                align-items: center;
                gap: 30px;

                .filter_label {
                    font-size: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #4f1a90;
                    gap: 10px;
                    height: 60px;
                    line-height: 60px;
                }
            }
        }

        .table_div {
            height: 500px;
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
