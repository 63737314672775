.transfer_out_token {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 185px;
    width: 100%;
    height: 744px;

    .transfer_out_token_step {
        width: 550px;
        height: 744px;

        .transfer_out_token_step1,
        .transfer_out_token_step2 {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 64px;

            .price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .price_div {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    :global .price_ic {
                        width: 50px;
                        height: 50px;
                    }

                    .price_usd {
                        color: #40077e;
                        font-family: "Noto Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 300px;
                    }

                    .price_tet {
                        color: #40077e;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        max-width: 300px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .all {
                    display: flex;
                    padding: 10px 28px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 16px;
                    background: linear-gradient(178deg, #4f1a90 5.68%, #8428f4 98.16%);
                    box-shadow: 3px 3px 0px 0px #541c9b;
                    cursor: pointer;
                }
            }

            .price_input {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;

                .input_div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .form_input {
                        border-radius: 16px;
                        border: 1px solid rgba(255, 255, 255, 0.15);
                        background: #40077e;
                        width: 563px;
                        height: 71px;
                        flex-shrink: 0;
                        color: rgba(255, 255, 255, 0.5);
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 0 20px;
                        box-sizing: border-box;
                        line-height: 70px;
                    }
                    :global .form_input {
                        width: 563px;
                    }
                    :global .ant-input::placeholder {
                        color: rgba(255, 255, 255, 0.5);
                    }
                    .form_input_info {
                        color: #2a005e;
                        font-family: "Noto Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: space-between;

                        .info_error {
                            color: #f01313;
                        }

                        .info_link {
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }

                .cash {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .cash_left,
                    .cash_right {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        cursor: pointer;

                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;

                        .setting_icon {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .ant-image {
                                width: 100%;
                            }
                        }

                        span {
                            color: #414993;
                        }
                    }

                    .cash_right {
                        width: 250px;
                    }
                }
                .locked {
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .line {
                    background: #40077e;
                    width: 100%;
                    height: 1px;
                }
                .fee {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .transfer_out_token_step2 {
            justify-content: flex-start;

            .top {
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 64px;

                :global {
                    .close_button {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        :global .button_common {
            height: 65px;
            font-size: 24px;
        }

        .cash_setting {
            width: 650px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;

            .cash_setting_items {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 625px;
                height: 399px;
                overflow: scroll;
                scrollbar-width: none;
                -ms-overflow-style: none;
                overflow-x: hidden;
                overflow-y: auto;

                .list_end {
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .cash_setting_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 158px;
                    height: 133px;
                    width: 100%;
                    padding: 0;

                    .cash_item_left {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 46px;

                        .cash_icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 186.481px;
                            height: 98.16px;
                            flex-shrink: 0;

                            :global .ant-image {
                                width: 100%;
                            }
                        }

                        .cash_item_discount {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 11px;

                            color: #40077e;
                            text-align: right;
                            font-family: "Noto Sans";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;

                            span {
                                color: #7650ca;
                                font-size: 18px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                :global .ant-spin-nested-loading .ant-spin-blur {
                    opacity: 0;
                }
            }

            .button_div {
                display: flex;
                padding: 28px 10px;
                justify-content: center;
                align-items: center;
                gap: 20px;

                :global .button_common {
                    height: 65px;
                    font-size: 24px;

                    .ant-image {
                        width: 39px;
                        height: 44.725px;
                    }
                }
            }
        }
    }

    .lucky_draw {
        width: 563px;
        height: 744px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 18px;

        .lucky_draw_title {
            color: #40077e;
            text-align: center;
            font-family: "Noto Sans";
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        .lucky_dray_content {
            display: flex;
            width: 563px;
            height: 563px;
            padding: 57px 31px 31px 32px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 24.62px;
            flex-shrink: 0;
            border-radius: 24px;
            background: #40077e;
            box-shadow: 5px 5px 0px 0px #6921c1;
            box-sizing: border-box;

            .lucky_draw_winners {
                width: 500px;
                height: 60px;
                flex-shrink: 0;
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #d0b6ee;
            }
            .notice {
                width: 500px;
                height: 60px;
                flex-shrink: 0;
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #d0b6ee;
                color: #40077e;
                font-family: "Noto Sans";
                font-size: 16px;
                font-weight: 700;
                line-height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                box-sizing: border-box;

                .notice_inner {
                    height: 100%;
                    overflow: hidden;
                }
                .notice_box {
                    animation: roll 40s linear infinite;
                }
                .notice_item {
                    height: 40px;
                    line-height: 40px;
                    white-space: nowrap;
                    // border-bottom: #2a005e 1px solid;
                }
                @keyframes roll {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(-800px);
                    }
                }
            }
        }

        .lucky_dray_bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 452.244px;
            color: #40077e;
            text-align: right;
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
        }

        .congratulations {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 14px;
            width: 380px;
            padding: 0 200px;

            :global .gif_img {
                width: 312px;
                height: 283px;
            }

            .congratulations_title {
                color: #40077e;
                text-align: right;
                font-family: "Noto Sans";
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .congratulations_subtitle {
                color: #7650ca;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            :global .button_common {
                height: 65px;
                font-size: 24px;
                text-transform: uppercase;
                margin-bottom: 30px;
                margin-top: 10px;
            }
        }

        .winning_records {
            display: inline-flex;
            flex-direction: column;
            gap: 14px;
            width: 610px;
            height: 493px;
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom: 50px;
            padding: 0 50px;

            .list_end {
                color: #7650ca;
                font-family: "Noto Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .winning_records_item {
                display: flex;
                align-items: center;
                width: 600px;
                padding: 16px 0;

                :global .gif_img {
                    width: 77.709px;
                    height: 70px;
                }

                .winning_records_item_content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .winning_records_title {
                        color: #40077e;
                        text-align: right;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    .winning_records_subtitle {
                        color: #7650ca;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }

            :global .ant-spin-nested-loading .ant-spin-blur {
                opacity: 0;
            }
        }
    }
}
