.list_nft_confirm {
    .nft_content {
        width: 800px;
        height: 440px;
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 0;
    }
    .nft_content_activate {
        width: 700px;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #40077e;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .nft_list {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        height: auto;
        filter: drop-shadow(3px 3px 1px rgba(124, 29, 201, 0.7));

        .nft_detail {
            background-color: #fff;
            border-radius: 20px;
            width: calc(100% - 10px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 109px;
            margin: 10px 0;
            padding: 0 20px;
            box-sizing: border-box;

            .nft_right {
                display: flex;
                gap: 20px;
                align-items: center;
                justify-content: space-between;

                .nft_img {
                    width: 68px;
                    height: 68px;
                    border-radius: 11px;
                }

                .nft_series {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;

                    .series {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #2e2139;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 32px;
                    }
                }
            }

            .nft_item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .nft_item_title {
                    color: #2e2139;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .nft_item_content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 8px;
                    color: #7c1dc9;
                    text-align: right;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .nft_item_usd {
                    color: #721ac9;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    .nft_message {
        height: 50px;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;

        .message_title {
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
        }

        .message_success {
            color: #2e2139;
        }

        .message_filed {
            color: #f00;
        }
    }

    .nft_button {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .button_common {
            font-size: 24px;
            height: 65px;
        }
    }
}
