.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .swiper_container {
        height: 140%;
        width: 100%;

        .arrow_button {
            width: 70px;
            height: 70px;
            z-index: 999999;
            position: absolute;
            left: 490px;
            top: calc(50% - 35px);
        }

        .arrow_right {
            left: 1360px;
        }

        .card,
        .empty_card {
            text-align: center;
            width: 690px;
            height: 374px;
            background: #fff;
            border-radius: 35px;
            transition: transform 300ms;
            transform: scale(0.62);
            display: flex;
            padding: 10px;
            filter: drop-shadow(10px 10px 0px #8582c7);

            &:last-child {
                margin-right: 0;
            }

            .card_inner {
                width: 100%;
                height: 100%;
                border-radius: 29px;

                .slider_inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background: #4DDBFF;
                    color: #fff;
                    font-size: 32px;
                }

                .pagination {
                    width: 164px;
                    height: 51px;
                    border-top-left-radius: 35px;
                    background-color: #fff;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .pointer {
                        width: 14px;
                        height: 13px;
                        border-radius: 50%;
                        margin-left: 10px;
                    }
                }
            }

            .empty_poster {
                width: 107%;
                height: 100%;
                border-radius: 29px;
            }
        }

        .swiper-slide-active,
        .swiper-slide-duplicate-active {
            transform: scale(1.08);
            filter: drop-shadow(10px 10px 0px #00000000);
        }
    }

    .swiper-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.spin_model {
    .ant-modal .ant-modal-content {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}
