.twinklingStars {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    // .star {
    //     position: absolute;
    // }
    // .star:nth-child(1) {
    //     animation: blink 1s infinite;
    //     // -webkit-transform: rotateZ(-10deg);
    //     top: 25%;
    //     right: 10%;
    //     filter: blur(4px);
    //     background-image: url("../../../assets/icon/stars1.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 220px;
    //     width: 220px;
    // }

    // .star:nth-child(2) {
    //     animation: blink 6s infinite;
    //     // -webkit-transform: rotateZ(8deg);
    //     top: 10%;
    //     left: 50%;
    //     filter: blur(8px);
    //     background-image: url("../../../assets/icon/stars2.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 220px;
    //     width: 220px;
    // }

    // .star:nth-child(3) {
    //     animation: blink 2s infinite;
    //     // -webkit-transform: rotateZ(-7deg);
    //     top: 50%;
    //     left: 10%;
    //     filter: blur(4px);
    //     background-image: url("../../../assets/icon/stars3.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 220px;
    //     width: 220px;
    // }

    // .star:nth-child(4) {
    //     animation: blink 5s infinite;
    //     bottom: 20%;
    //     left: 25%;
    //     filter: blur(2px);
    //     background-image: url("../../../assets/icon/stars4.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 86px;
    //     width: 107px;
    // }

    // .star:nth-child(5) {
    //     animation: blink 4s infinite;
    //     bottom: 30%;
    //     right: 20%;
    //     filter: blur(2px);
    //     background-image: url("../../../assets/icon/stars5.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 90px;
    //     width: 70px;
    // }

    // .star:nth-child(6) {
    //     animation: blink 3s infinite;
    //     top: 25%;
    //     left: 15%;
    //     filter: blur(2px);
    //     background-image: url("../../../assets/icon/stars6.png");
    //     background-repeat: no-repeat;
    //     background-size: 100% 100%;
    //     height: 90px;
    //     width: 70px;
    // }

    // @keyframes blink {
    //     50% {
    //         opacity: 0;
    //         transform: scale(0.5);
    //         filter: blur(0px);
    //         -webkit-transform: rotateZ(0deg);
    //     }
    // }

    /* Animated Stars */

    .stars1 {
        width: 1px;
        height: 1px;
        display: block;
        background: transparent;
        box-shadow:
            785px 1440px #fff,
            1656px 752px #fff,
            945px 1699px #fff,
            358px 442px #fff,
            730px 1639px #fff,
            1489px 427px #fff,
            1134px 1408px #fff,
            1620px 435px #fff,
            758px 1131px #fff,
            1930px 176px #fff,
            613px 394px #fff,
            994px 1769px #fff,
            137px 995px #fff,
            975px 1664px #fff,
            1308px 1421px #fff,
            653px 117px #fff,
            1297px 443px #fff,
            1803px 1551px #fff,
            57px 1304px #fff,
            1006px 985px #fff,
            1147px 771px #fff,
            487px 394px #fff,
            1148px 1328px #fff,
            858px 1537px #fff,
            1595px 415px #fff,
            1325px 1801px #fff,
            1549px 1569px #fff,
            1131px 1538px #fff,
            1268px 477px #fff,
            771px 1596px #fff,
            1486px 1771px #fff,
            1481px 1961px #fff,
            517px 1988px #fff,
            642px 588px #fff,
            1835px 1441px #fff,
            569px 716px #fff,
            1604px 572px #fff,
            1782px 441px #fff,
            1218px 503px #fff,
            1402px 684px #fff,
            1303px 621px #fff,
            892px 641px #fff,
            1254px 1496px #fff,
            234px 669px #fff,
            554px 1039px #fff,
            312px 175px #fff,
            1243px 1897px #fff,
            234px 897px #fff,
            1617px 437px #fff,
            687px 171px #fff,
            1821px 1883px #fff,
            296px 1056px #fff,
            1046px 1205px #fff,
            577px 221px #fff,
            886px 1764px #fff,
            473px 482px #fff,
            1556px 270px #fff,
            1607px 352px #fff,
            1993px 685px #fff,
            109px 1592px #fff,
            1335px 235px #fff,
            680px 548px #fff,
            1917px 1834px #fff,
            865px 362px #fff,
            1349px 1092px #fff,
            475px 1491px #fff,
            1096px 955px #fff,
            1863px 228px #fff,
            1635px 233px #fff,
            1313px 1688px #fff,
            180px 1258px #fff,
            1397px 988px #fff,
            882px 867px #fff,
            484px 912px #fff,
            1358px 837px #fff,
            1821px 1229px #fff,
            1287px 1689px #fff,
            36px 880px #fff,
            1594px 682px #fff,
            613px 195px #fff,
            1305px 941px #fff,
            559px 1362px #fff,
            1142px 859px #fff,
            921px 850px #fff,
            12px 1630px #fff,
            554px 979px #fff,
            1708px 1290px #fff,
            1492px 1522px #fff,
            1112px 169px #fff,
            624px 48px #fff,
            829px 1442px #fff,
            1018px 283px #fff,
            1433px 97px #fff,
            1084px 1719px #fff,
            1794px 1900px #fff,
            1410px 1216px #fff,
            1180px 341px #fff,
            135px 950px #fff,
            1306px 905px #fff,
            651px 9px #fff,
            663px 770px #fff,
            566px 1280px #fff,
            1930px 1879px #fff,
            418px 1230px #fff,
            1245px 1522px #fff,
            1852px 1498px #fff,
            1634px 193px #fff,
            841px 1197px #fff,
            661px 1667px #fff,
            291px 620px #fff,
            1527px 587px #fff,
            818px 822px #fff,
            765px 1858px #fff,
            1270px 1151px #fff,
            687px 918px #fff,
            404px 980px #fff,
            371px 773px #fff,
            492px 238px #fff,
            115px 717px #fff,
            1164px 1120px #fff,
            1036px 508px #fff,
            419px 96px #fff,
            883px 1048px #fff,
            220px 929px #fff,
            1275px 1476px #fff,
            1633px 1370px #fff,
            754px 316px #fff,
            1566px 1027px #fff,
            1728px 1410px #fff,
            319px 1731px #fff,
            191px 1326px #fff,
            391px 1419px #fff,
            1495px 1885px #fff,
            1393px 675px #fff,
            543px 720px #fff,
            1254px 289px #fff,
            641px 1676px #fff,
            1391px 393px #fff,
            1774px 1714px #fff,
            1079px 1130px #fff,
            1536px 141px #fff,
            1121px 131px #fff,
            1045px 896px #fff,
            1719px 1476px #fff,
            672px 1967px #fff,
            209px 1962px #fff,
            1504px 784px #fff,
            1164px 1880px #fff,
            205px 1455px #fff,
            1090px 1043px #fff,
            1648px 1369px #fff,
            905px 176px #fff,
            40px 1840px #fff,
            357px 721px #fff,
            1262px 469px #fff,
            1889px 1140px #fff,
            1706px 1487px #fff,
            98px 1723px #fff,
            1564px 332px #fff,
            1688px 495px #fff,
            1844px 1691px #fff,
            1788px 1158px #fff,
            1222px 1070px #fff,
            54px 605px #fff,
            1850px 1335px #fff,
            1899px 776px #fff,
            1459px 1942px #fff,
            1370px 1906px #fff,
            1017px 1359px #fff,
            1485px 859px #fff,
            405px 1235px #fff,
            1529px 962px #fff,
            348px 74px #fff,
            165px 1114px #fff,
            1972px 441px #fff,
            92px 1874px #fff,
            966px 253px #fff,
            281px 733px #fff,
            1759px 1235px #fff,
            461px 1224px #fff,
            1849px 234px #fff,
            1316px 457px #fff,
            390px 343px #fff,
            1888px 1030px #fff,
            1705px 1778px #fff,
            734px 1365px #fff,
            589px 1227px #fff,
            1586px 1163px #fff,
            768px 841px #fff,
            1643px 1619px #fff,
            1527px 1909px #fff,
            249px 690px #fff,
            1894px 209px #fff,
            269px 106px #fff,
            1040px 1323px #fff,
            1162px 873px #fff,
            939px 1074px #fff,
            245px 842px #fff,
            702px 657px #fff,
            1300px 286px #fff,
            1452px 419px #fff,
            9px 318px #fff,
            1198px 1073px #fff,
            1449px 1463px #fff,
            1207px 103px #fff,
            1532px 42px #fff,
            19px 874px #fff,
            474px 720px #fff,
            1579px 579px #fff,
            814px 57px #fff,
            939px 675px #fff,
            1407px 490px #fff,
            460px 565px #fff,
            1314px 2000px #fff,
            567px 1187px #fff,
            197px 559px #fff,
            10px 840px #fff,
            1149px 1455px #fff,
            1918px 867px #fff,
            1992px 1999px #fff,
            912px 1824px #fff,
            124px 1015px #fff,
            626px 1967px #fff,
            584px 626px #fff,
            896px 647px #fff,
            1287px 682px #fff,
            1524px 1205px #fff,
            642px 682px #fff,
            1846px 1584px #fff,
            1869px 1963px #fff,
            291px 1844px #fff,
            1559px 1859px #fff,
            203px 1087px #fff,
            1277px 934px #fff,
            1888px 818px #fff,
            202px 162px #fff,
            896px 1060px #fff,
            1311px 1220px #fff,
            1738px 938px #fff,
            983px 161px #fff,
            1258px 1900px #fff,
            1083px 401px #fff,
            882px 185px #fff,
            594px 983px #fff,
            1128px 1106px #fff,
            401px 705px #fff,
            873px 1121px #fff,
            1998px 260px #fff,
            192px 674px #fff,
            1040px 1484px #fff,
            239px 1703px #fff,
            247px 311px #fff,
            756px 1455px #fff,
            207px 1426px #fff,
            607px 720px #fff,
            1701px 1005px #fff,
            82px 1273px #fff,
            1657px 1967px #fff,
            23px 883px #fff,
            1678px 837px #fff,
            1314px 1208px #fff,
            281px 1788px #fff,
            1428px 1804px #fff,
            1129px 1949px #fff,
            1766px 268px #fff,
            1811px 248px #fff,
            1766px 1630px #fff,
            944px 1194px #fff,
            894px 1402px #fff,
            1489px 618px #fff,
            524px 1492px #fff,
            242px 1741px #fff,
            777px 1047px #fff,
            271px 94px #fff,
            314px 161px #fff,
            382px 678px #fff,
            339px 1526px #fff,
            1855px 354px #fff,
            1369px 841px #fff,
            96px 889px #fff,
            20px 696px #fff,
            1503px 1325px #fff,
            987px 366px #fff,
            330px 979px #fff,
            1076px 432px #fff,
            131px 189px #fff,
            509px 943px #fff,
            646px 1321px #fff,
            1453px 1349px #fff,
            538px 1519px #fff,
            1671px 1462px #fff,
            1599px 301px #fff,
            1038px 1939px #fff,
            926px 591px #fff,
            1445px 1418px #fff,
            847px 303px #fff,
            1310px 1349px #fff,
            717px 1359px #fff,
            222px 591px #fff,
            17px 1400px #fff,
            1394px 426px #fff,
            546px 1305px #fff,
            295px 1745px #fff,
            105px 237px #fff,
            1041px 82px #fff,
            182px 982px #fff,
            1822px 442px #fff,
            159px 326px #fff,
            1851px 1066px #fff,
            1942px 272px #fff,
            437px 1615px #fff,
            1578px 1936px #fff,
            1361px 143px #fff,
            1675px 519px #fff,
            1304px 894px #fff,
            1415px 166px #fff,
            186px 80px #fff,
            1064px 1667px #fff,
            1983px 1012px #fff,
            1663px 1901px #fff,
            1671px 530px #fff,
            1353px 264px #fff,
            724px 1144px #fff,
            472px 207px #fff,
            1498px 401px #fff,
            243px 462px #fff,
            37px 590px #fff,
            1180px 1388px #fff,
            314px 532px #fff,
            1668px 1118px #fff,
            528px 1661px #fff,
            767px 453px #fff,
            1346px 891px #fff,
            954px 1156px #fff,
            993px 1002px #fff,
            879px 1576px #fff,
            481px 86px #fff,
            788px 754px #fff,
            1499px 1664px #fff,
            1468px 1640px #fff,
            1362px 1877px #fff,
            1886px 269px #fff,
            1691px 1799px #fff,
            1130px 1149px #fff,
            484px 320px #fff,
            102px 36px #fff,
            1423px 396px #fff,
            2000px 1005px #fff,
            768px 1863px #fff,
            1791px 1679px #fff,
            1269px 1453px #fff,
            1158px 938px #fff,
            1960px 1820px #fff,
            151px 1296px #fff,
            214px 325px #fff,
            19px 1713px #fff,
            1684px 1276px #fff,
            1093px 1054px #fff,
            1684px 87px #fff,
            962px 1473px #fff,
            1840px 238px #fff,
            1563px 1367px #fff,
            1065px 627px #fff,
            569px 1768px #fff,
            70px 456px #fff,
            1183px 960px #fff,
            899px 1839px #fff,
            1875px 1652px #fff,
            1060px 549px #fff,
            796px 575px #fff,
            34px 886px #fff,
            762px 388px #fff,
            1702px 1057px #fff,
            591px 1549px #fff,
            297px 811px #fff,
            593px 1079px #fff,
            1838px 1615px #fff,
            68px 1983px #fff,
            1054px 1794px #fff,
            229px 1771px #fff,
            1726px 789px #fff,
            833px 1032px #fff,
            622px 1043px #fff,
            1298px 789px #fff,
            169px 1637px #fff,
            377px 1317px #fff,
            919px 1217px #fff,
            146px 1581px #fff,
            1321px 597px #fff,
            1871px 577px #fff,
            279px 1661px #fff,
            226px 1585px #fff,
            538px 419px #fff,
            1495px 1262px #fff,
            58px 1421px #fff,
            1484px 180px #fff,
            1824px 1742px #fff,
            63px 153px #fff,
            820px 1654px #fff,
            1862px 158px #fff,
            6px 303px #fff,
            808px 1580px #fff,
            1361px 44px #fff,
            1300px 158px #fff,
            1614px 165px #fff,
            603px 226px #fff,
            383px 1085px #fff,
            1089px 539px #fff,
            1536px 989px #fff,
            915px 1819px #fff,
            1560px 235px #fff,
            1228px 1767px #fff,
            1546px 386px #fff,
            1203px 1322px #fff,
            1290px 1063px #fff,
            506px 706px #fff,
            406px 521px #fff,
            1102px 297px #fff,
            952px 896px #fff,
            1080px 477px #fff,
            524px 1026px #fff,
            680px 1544px #fff,
            1654px 639px #fff,
            845px 1980px #fff,
            1304px 1236px #fff,
            265px 1848px #fff,
            1705px 809px #fff,
            1224px 708px #fff,
            780px 1891px #fff,
            405px 1329px #fff,
            1236px 253px #fff,
            438px 1033px #fff,
            1309px 830px #fff,
            794px 1564px #fff,
            35px 1646px #fff,
            937px 1671px #fff,
            1473px 1608px #fff,
            1499px 210px #fff,
            763px 1927px #fff,
            18px 1465px #fff,
            1352px 531px #fff,
            1063px 1464px #fff,
            349px 480px #fff,
            1910px 476px #fff,
            802px 1745px #fff,
            139px 1520px #fff,
            1804px 1510px #fff,
            1654px 1038px #fff,
            1642px 740px #fff,
            315px 51px #fff,
            1443px 1625px #fff,
            877px 126px #fff,
            768px 1493px #fff,
            1995px 553px #fff,
            1234px 1101px #fff,
            404px 1037px #fff,
            1751px 1618px #fff,
            1904px 671px #fff,
            1365px 943px #fff,
            1829px 1502px #fff,
            1273px 325px #fff,
            1680px 51px #fff,
            1450px 539px #fff,
            164px 445px #fff,
            1866px 989px #fff,
            301px 1090px #fff,
            935px 1225px #fff,
            378px 1760px #fff,
            607px 549px #fff,
            646px 1947px #fff,
            1056px 1273px #fff,
            991px 989px #fff,
            278px 115px #fff,
            1547px 58px #fff,
            223px 876px #fff,
            1957px 94px #fff,
            1016px 674px #fff,
            1035px 476px #fff,
            1794px 1536px #fff,
            1284px 1052px #fff,
            932px 1616px #fff,
            377px 309px #fff,
            67px 275px #fff,
            1377px 185px #fff,
            310px 1304px #fff,
            1793px 551px #fff,
            620px 1269px #fff,
            742px 1991px #fff,
            513px 1625px #fff,
            948px 1276px #fff,
            750px 1928px #fff,
            690px 941px #fff,
            1487px 468px #fff,
            59px 1504px #fff,
            1554px 958px #fff,
            225px 1062px #fff,
            655px 832px #fff,
            132px 1466px #fff,
            2000px 971px #fff,
            289px 234px #fff,
            852px 754px #fff,
            1131px 1666px #fff,
            942px 427px #fff,
            1674px 1133px #fff,
            1668px 552px #fff,
            929px 1353px #fff,
            1213px 963px #fff,
            1617px 919px #fff,
            38px 145px #fff,
            56px 1030px #fff,
            1621px 835px #fff,
            423px 509px #fff,
            1403px 1195px #fff,
            1378px 1468px #fff,
            561px 1625px #fff,
            258px 929px #fff,
            1475px 1127px #fff,
            1443px 177px #fff,
            1660px 1771px #fff,
            817px 1328px #fff,
            1822px 1002px #fff,
            1565px 237px #fff,
            1377px 638px #fff,
            619px 424px #fff,
            753px 1182px #fff,
            502px 674px #fff,
            1100px 788px #fff,
            931px 515px #fff,
            1716px 689px #fff,
            591px 270px #fff,
            272px 627px #fff,
            1576px 466px #fff,
            1401px 1809px #fff,
            1375px 997px #fff,
            1133px 1556px #fff,
            1326px 439px #fff,
            1801px 1445px #fff,
            409px 447px #fff,
            212px 617px #fff,
            1438px 1766px #fff,
            410px 767px #fff,
            82px 1180px #fff,
            973px 438px #fff,
            1056px 27px #fff,
            1104px 137px #fff,
            889px 1330px #fff,
            1017px 1867px #fff,
            746px 1519px #fff,
            1441px 942px #fff,
            139px 1013px #fff,
            1966px 1812px #fff,
            967px 1057px #fff,
            356px 840px #fff,
            400px 1283px #fff,
            1424px 1306px #fff,
            720px 726px #fff,
            402px 1889px #fff,
            445px 1627px #fff,
            1021px 1535px #fff,
            614px 166px #fff,
            1452px 1021px #fff,
            1292px 1620px #fff,
            1245px 1553px #fff,
            1939px 275px #fff,
            1975px 1462px #fff,
            1865px 210px #fff,
            846px 1994px #fff,
            8px 1539px #fff,
            714px 407px #fff,
            1199px 1367px #fff,
            673px 464px #fff,
            1745px 1811px #fff,
            1140px 1036px #fff,
            899px 1086px #fff,
            1740px 496px #fff,
            1311px 267px #fff,
            1995px 1514px #fff,
            1133px 760px #fff,
            1688px 1836px #fff,
            626px 366px #fff,
            37px 1495px #fff,
            834px 1255px #fff,
            1605px 1505px #fff,
            732px 1912px #fff,
            872px 440px #fff,
            324px 1686px #fff,
            403px 279px #fff,
            1358px 1038px #fff,
            1274px 226px #fff,
            505px 1352px #fff,
            355px 1061px #fff,
            1064px 697px #fff,
            1022px 684px #fff,
            1062px 1742px #fff,
            1007px 993px #fff,
            645px 1324px #fff,
            1348px 549px #fff,
            906px 483px #fff,
            1319px 1472px #fff,
            128px 1454px #fff,
            20px 1360px #fff,
            1189px 1519px #fff,
            1050px 853px #fff,
            1696px 1017px #fff,
            29px 1907px #fff,
            1017px 638px #fff,
            1905px 882px #fff,
            160px 1033px #fff,
            1043px 843px #fff,
            1529px 121px #fff,
            382px 673px #fff,
            1399px 452px #fff,
            1342px 1265px #fff,
            614px 1479px #fff,
            217px 1222px #fff,
            1183px 441px #fff,
            588px 89px #fff,
            899px 1837px #fff,
            1708px 1661px #fff,
            1150px 1770px #fff,
            1296px 1742px #fff,
            848px 1464px #fff,
            1613px 1686px #fff,
            416px 1047px #fff,
            447px 483px #fff,
            307px 1444px #fff,
            1904px 1490px #fff,
            1493px 777px #fff,
            906px 800px #fff,
            1529px 70px #fff,
            846px 456px #fff,
            1250px 1288px #fff,
            767px 245px #fff,
            794px 723px #fff,
            990px 1653px #fff,
            1201px 1952px #fff,
            1815px 537px #fff,
            106px 1958px #fff,
            146px 867px #fff,
            1923px 1731px #fff,
            87px 877px #fff,
            1663px 1769px #fff,
            1233px 228px #fff,
            544px 378px #fff,
            182px 1800px #fff,
            53px 1661px #fff,
            635px 988px #fff,
            1490px 1199px #fff,
            1015px 732px #fff,
            595px 1970px #fff,
            870px 1596px #fff,
            1755px 913px #fff,
            1785px 735px #fff,
            212px 299px #fff,
            915px 1895px #fff,
            1818px 1568px #fff,
            1326px 135px #fff,
            1436px 111px #fff,
            561px 1695px #fff,
            232px 439px #fff,
            1660px 1435px #fff,
            201px 1725px #fff,
            1466px 904px #fff,
            467px 1067px #fff,
            471px 536px #fff,
            685px 705px #fff,
            1911px 315px #fff,
            324px 1050px #fff,
            1412px 26px #fff,
            210px 295px #fff,
            356px 1553px #fff,
            1673px 1275px #fff,
            899px 1252px #fff,
            1630px 630px #fff,
            1538px 984px #fff,
            1330px 1099px #fff,
            1449px 550px #fff,
            807px 881px #fff,
            1344px 1334px #fff,
            1529px 1838px #fff,
            1624px 1320px #fff,
            75px 1206px #fff,
            1261px 1201px #fff,
            1515px 1577px #fff,
            1233px 47px #fff,
            486px 140px #fff,
            1591px 1386px #fff,
            196px 688px #fff,
            855px 1086px #fff,
            538px 1551px #fff,
            1019px 1122px #fff,
            665px 1600px #fff,
            1666px 1979px #fff,
            1329px 557px #fff,
            1213px 1281px #fff,
            1784px 1614px #fff,
            1766px 30px #fff,
            831px 1591px #fff,
            1589px 207px #fff,
            1660px 1151px #fff;
        animation: animStar 50s linear infinite;
    }
    .stars1:after {
        content: " ";
        position: absolute;
        left: -2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow:
            785px 1440px #fff,
            1656px 752px #fff,
            945px 1699px #fff,
            358px 442px #fff,
            730px 1639px #fff,
            1489px 427px #fff,
            1134px 1408px #fff,
            1620px 435px #fff,
            758px 1131px #fff,
            1930px 176px #fff,
            613px 394px #fff,
            994px 1769px #fff,
            137px 995px #fff,
            975px 1664px #fff,
            1308px 1421px #fff,
            653px 117px #fff,
            1297px 443px #fff,
            1803px 1551px #fff,
            57px 1304px #fff,
            1006px 985px #fff,
            1147px 771px #fff,
            487px 394px #fff,
            1148px 1328px #fff,
            858px 1537px #fff,
            1595px 415px #fff,
            1325px 1801px #fff,
            1549px 1569px #fff,
            1131px 1538px #fff,
            1268px 477px #fff,
            771px 1596px #fff,
            1486px 1771px #fff,
            1481px 1961px #fff,
            517px 1988px #fff,
            642px 588px #fff,
            1835px 1441px #fff,
            569px 716px #fff,
            1604px 572px #fff,
            1782px 441px #fff,
            1218px 503px #fff,
            1402px 684px #fff,
            1303px 621px #fff,
            892px 641px #fff,
            1254px 1496px #fff,
            234px 669px #fff,
            554px 1039px #fff,
            312px 175px #fff,
            1243px 1897px #fff,
            234px 897px #fff,
            1617px 437px #fff,
            687px 171px #fff,
            1821px 1883px #fff,
            296px 1056px #fff,
            1046px 1205px #fff,
            577px 221px #fff,
            886px 1764px #fff,
            473px 482px #fff,
            1556px 270px #fff,
            1607px 352px #fff,
            1993px 685px #fff,
            109px 1592px #fff,
            1335px 235px #fff,
            680px 548px #fff,
            1917px 1834px #fff,
            865px 362px #fff,
            1349px 1092px #fff,
            475px 1491px #fff,
            1096px 955px #fff,
            1863px 228px #fff,
            1635px 233px #fff,
            1313px 1688px #fff,
            180px 1258px #fff,
            1397px 988px #fff,
            882px 867px #fff,
            484px 912px #fff,
            1358px 837px #fff,
            1821px 1229px #fff,
            1287px 1689px #fff,
            36px 880px #fff,
            1594px 682px #fff,
            613px 195px #fff,
            1305px 941px #fff,
            559px 1362px #fff,
            1142px 859px #fff,
            921px 850px #fff,
            12px 1630px #fff,
            554px 979px #fff,
            1708px 1290px #fff,
            1492px 1522px #fff,
            1112px 169px #fff,
            624px 48px #fff,
            829px 1442px #fff,
            1018px 283px #fff,
            1433px 97px #fff,
            1084px 1719px #fff,
            1794px 1900px #fff,
            1410px 1216px #fff,
            1180px 341px #fff,
            135px 950px #fff,
            1306px 905px #fff,
            651px 9px #fff,
            663px 770px #fff,
            566px 1280px #fff,
            1930px 1879px #fff,
            418px 1230px #fff,
            1245px 1522px #fff,
            1852px 1498px #fff,
            1634px 193px #fff,
            841px 1197px #fff,
            661px 1667px #fff,
            291px 620px #fff,
            1527px 587px #fff,
            818px 822px #fff,
            765px 1858px #fff,
            1270px 1151px #fff,
            687px 918px #fff,
            404px 980px #fff,
            371px 773px #fff,
            492px 238px #fff,
            115px 717px #fff,
            1164px 1120px #fff,
            1036px 508px #fff,
            419px 96px #fff,
            883px 1048px #fff,
            220px 929px #fff,
            1275px 1476px #fff,
            1633px 1370px #fff,
            754px 316px #fff,
            1566px 1027px #fff,
            1728px 1410px #fff,
            319px 1731px #fff,
            191px 1326px #fff,
            391px 1419px #fff,
            1495px 1885px #fff,
            1393px 675px #fff,
            543px 720px #fff,
            1254px 289px #fff,
            641px 1676px #fff,
            1391px 393px #fff,
            1774px 1714px #fff,
            1079px 1130px #fff,
            1536px 141px #fff,
            1121px 131px #fff,
            1045px 896px #fff,
            1719px 1476px #fff,
            672px 1967px #fff,
            209px 1962px #fff,
            1504px 784px #fff,
            1164px 1880px #fff,
            205px 1455px #fff,
            1090px 1043px #fff,
            1648px 1369px #fff,
            905px 176px #fff,
            40px 1840px #fff,
            357px 721px #fff,
            1262px 469px #fff,
            1889px 1140px #fff,
            1706px 1487px #fff,
            98px 1723px #fff,
            1564px 332px #fff,
            1688px 495px #fff,
            1844px 1691px #fff,
            1788px 1158px #fff,
            1222px 1070px #fff,
            54px 605px #fff,
            1850px 1335px #fff,
            1899px 776px #fff,
            1459px 1942px #fff,
            1370px 1906px #fff,
            1017px 1359px #fff,
            1485px 859px #fff,
            405px 1235px #fff,
            1529px 962px #fff,
            348px 74px #fff,
            165px 1114px #fff,
            1972px 441px #fff,
            92px 1874px #fff,
            966px 253px #fff,
            281px 733px #fff,
            1759px 1235px #fff,
            461px 1224px #fff,
            1849px 234px #fff,
            1316px 457px #fff,
            390px 343px #fff,
            1888px 1030px #fff,
            1705px 1778px #fff,
            734px 1365px #fff,
            589px 1227px #fff,
            1586px 1163px #fff,
            768px 841px #fff,
            1643px 1619px #fff,
            1527px 1909px #fff,
            249px 690px #fff,
            1894px 209px #fff,
            269px 106px #fff,
            1040px 1323px #fff,
            1162px 873px #fff,
            939px 1074px #fff,
            245px 842px #fff,
            702px 657px #fff,
            1300px 286px #fff,
            1452px 419px #fff,
            9px 318px #fff,
            1198px 1073px #fff,
            1449px 1463px #fff,
            1207px 103px #fff,
            1532px 42px #fff,
            19px 874px #fff,
            474px 720px #fff,
            1579px 579px #fff,
            814px 57px #fff,
            939px 675px #fff,
            1407px 490px #fff,
            460px 565px #fff,
            1314px 2000px #fff,
            567px 1187px #fff,
            197px 559px #fff,
            10px 840px #fff,
            1149px 1455px #fff,
            1918px 867px #fff,
            1992px 1999px #fff,
            912px 1824px #fff,
            124px 1015px #fff,
            626px 1967px #fff,
            584px 626px #fff,
            896px 647px #fff,
            1287px 682px #fff,
            1524px 1205px #fff,
            642px 682px #fff,
            1846px 1584px #fff,
            1869px 1963px #fff,
            291px 1844px #fff,
            1559px 1859px #fff,
            203px 1087px #fff,
            1277px 934px #fff,
            1888px 818px #fff,
            202px 162px #fff,
            896px 1060px #fff,
            1311px 1220px #fff,
            1738px 938px #fff,
            983px 161px #fff,
            1258px 1900px #fff,
            1083px 401px #fff,
            882px 185px #fff,
            594px 983px #fff,
            1128px 1106px #fff,
            401px 705px #fff,
            873px 1121px #fff,
            1998px 260px #fff,
            192px 674px #fff,
            1040px 1484px #fff,
            239px 1703px #fff,
            247px 311px #fff,
            756px 1455px #fff,
            207px 1426px #fff,
            607px 720px #fff,
            1701px 1005px #fff,
            82px 1273px #fff,
            1657px 1967px #fff,
            23px 883px #fff,
            1678px 837px #fff,
            1314px 1208px #fff,
            281px 1788px #fff,
            1428px 1804px #fff,
            1129px 1949px #fff,
            1766px 268px #fff,
            1811px 248px #fff,
            1766px 1630px #fff,
            944px 1194px #fff,
            894px 1402px #fff,
            1489px 618px #fff,
            524px 1492px #fff,
            242px 1741px #fff,
            777px 1047px #fff,
            271px 94px #fff,
            314px 161px #fff,
            382px 678px #fff,
            339px 1526px #fff,
            1855px 354px #fff,
            1369px 841px #fff,
            96px 889px #fff,
            20px 696px #fff,
            1503px 1325px #fff,
            987px 366px #fff,
            330px 979px #fff,
            1076px 432px #fff,
            131px 189px #fff,
            509px 943px #fff,
            646px 1321px #fff,
            1453px 1349px #fff,
            538px 1519px #fff,
            1671px 1462px #fff,
            1599px 301px #fff,
            1038px 1939px #fff,
            926px 591px #fff,
            1445px 1418px #fff,
            847px 303px #fff,
            1310px 1349px #fff,
            717px 1359px #fff,
            222px 591px #fff,
            17px 1400px #fff,
            1394px 426px #fff,
            546px 1305px #fff,
            295px 1745px #fff,
            105px 237px #fff,
            1041px 82px #fff,
            182px 982px #fff,
            1822px 442px #fff,
            159px 326px #fff,
            1851px 1066px #fff,
            1942px 272px #fff,
            437px 1615px #fff,
            1578px 1936px #fff,
            1361px 143px #fff,
            1675px 519px #fff,
            1304px 894px #fff,
            1415px 166px #fff,
            186px 80px #fff,
            1064px 1667px #fff,
            1983px 1012px #fff,
            1663px 1901px #fff,
            1671px 530px #fff,
            1353px 264px #fff,
            724px 1144px #fff,
            472px 207px #fff,
            1498px 401px #fff,
            243px 462px #fff,
            37px 590px #fff,
            1180px 1388px #fff,
            314px 532px #fff,
            1668px 1118px #fff,
            528px 1661px #fff,
            767px 453px #fff,
            1346px 891px #fff,
            954px 1156px #fff,
            993px 1002px #fff,
            879px 1576px #fff,
            481px 86px #fff,
            788px 754px #fff,
            1499px 1664px #fff,
            1468px 1640px #fff,
            1362px 1877px #fff,
            1886px 269px #fff,
            1691px 1799px #fff,
            1130px 1149px #fff,
            484px 320px #fff,
            102px 36px #fff,
            1423px 396px #fff,
            2000px 1005px #fff,
            768px 1863px #fff,
            1791px 1679px #fff,
            1269px 1453px #fff,
            1158px 938px #fff,
            1960px 1820px #fff,
            151px 1296px #fff,
            214px 325px #fff,
            19px 1713px #fff,
            1684px 1276px #fff,
            1093px 1054px #fff,
            1684px 87px #fff,
            962px 1473px #fff,
            1840px 238px #fff,
            1563px 1367px #fff,
            1065px 627px #fff,
            569px 1768px #fff,
            70px 456px #fff,
            1183px 960px #fff,
            899px 1839px #fff,
            1875px 1652px #fff,
            1060px 549px #fff,
            796px 575px #fff,
            34px 886px #fff,
            762px 388px #fff,
            1702px 1057px #fff,
            591px 1549px #fff,
            297px 811px #fff,
            593px 1079px #fff,
            1838px 1615px #fff,
            68px 1983px #fff,
            1054px 1794px #fff,
            229px 1771px #fff,
            1726px 789px #fff,
            833px 1032px #fff,
            622px 1043px #fff,
            1298px 789px #fff,
            169px 1637px #fff,
            377px 1317px #fff,
            919px 1217px #fff,
            146px 1581px #fff,
            1321px 597px #fff,
            1871px 577px #fff,
            279px 1661px #fff,
            226px 1585px #fff,
            538px 419px #fff,
            1495px 1262px #fff,
            58px 1421px #fff,
            1484px 180px #fff,
            1824px 1742px #fff,
            63px 153px #fff,
            820px 1654px #fff,
            1862px 158px #fff,
            6px 303px #fff,
            808px 1580px #fff,
            1361px 44px #fff,
            1300px 158px #fff,
            1614px 165px #fff,
            603px 226px #fff,
            383px 1085px #fff,
            1089px 539px #fff,
            1536px 989px #fff,
            915px 1819px #fff,
            1560px 235px #fff,
            1228px 1767px #fff,
            1546px 386px #fff,
            1203px 1322px #fff,
            1290px 1063px #fff,
            506px 706px #fff,
            406px 521px #fff,
            1102px 297px #fff,
            952px 896px #fff,
            1080px 477px #fff,
            524px 1026px #fff,
            680px 1544px #fff,
            1654px 639px #fff,
            845px 1980px #fff,
            1304px 1236px #fff,
            265px 1848px #fff,
            1705px 809px #fff,
            1224px 708px #fff,
            780px 1891px #fff,
            405px 1329px #fff,
            1236px 253px #fff,
            438px 1033px #fff,
            1309px 830px #fff,
            794px 1564px #fff,
            35px 1646px #fff,
            937px 1671px #fff,
            1473px 1608px #fff,
            1499px 210px #fff,
            763px 1927px #fff,
            18px 1465px #fff,
            1352px 531px #fff,
            1063px 1464px #fff,
            349px 480px #fff,
            1910px 476px #fff,
            802px 1745px #fff,
            139px 1520px #fff,
            1804px 1510px #fff,
            1654px 1038px #fff,
            1642px 740px #fff,
            315px 51px #fff,
            1443px 1625px #fff,
            877px 126px #fff,
            768px 1493px #fff,
            1995px 553px #fff,
            1234px 1101px #fff,
            404px 1037px #fff,
            1751px 1618px #fff,
            1904px 671px #fff,
            1365px 943px #fff,
            1829px 1502px #fff,
            1273px 325px #fff,
            1680px 51px #fff,
            1450px 539px #fff,
            164px 445px #fff,
            1866px 989px #fff,
            301px 1090px #fff,
            935px 1225px #fff,
            378px 1760px #fff,
            607px 549px #fff,
            646px 1947px #fff,
            1056px 1273px #fff,
            991px 989px #fff,
            278px 115px #fff,
            1547px 58px #fff,
            223px 876px #fff,
            1957px 94px #fff,
            1016px 674px #fff,
            1035px 476px #fff,
            1794px 1536px #fff,
            1284px 1052px #fff,
            932px 1616px #fff,
            377px 309px #fff,
            67px 275px #fff,
            1377px 185px #fff,
            310px 1304px #fff,
            1793px 551px #fff,
            620px 1269px #fff,
            742px 1991px #fff,
            513px 1625px #fff,
            948px 1276px #fff,
            750px 1928px #fff,
            690px 941px #fff,
            1487px 468px #fff,
            59px 1504px #fff,
            1554px 958px #fff,
            225px 1062px #fff,
            655px 832px #fff,
            132px 1466px #fff,
            2000px 971px #fff,
            289px 234px #fff,
            852px 754px #fff,
            1131px 1666px #fff,
            942px 427px #fff,
            1674px 1133px #fff,
            1668px 552px #fff,
            929px 1353px #fff,
            1213px 963px #fff,
            1617px 919px #fff,
            38px 145px #fff,
            56px 1030px #fff,
            1621px 835px #fff,
            423px 509px #fff,
            1403px 1195px #fff,
            1378px 1468px #fff,
            561px 1625px #fff,
            258px 929px #fff,
            1475px 1127px #fff,
            1443px 177px #fff,
            1660px 1771px #fff,
            817px 1328px #fff,
            1822px 1002px #fff,
            1565px 237px #fff,
            1377px 638px #fff,
            619px 424px #fff,
            753px 1182px #fff,
            502px 674px #fff,
            1100px 788px #fff,
            931px 515px #fff,
            1716px 689px #fff,
            591px 270px #fff,
            272px 627px #fff,
            1576px 466px #fff,
            1401px 1809px #fff,
            1375px 997px #fff,
            1133px 1556px #fff,
            1326px 439px #fff,
            1801px 1445px #fff,
            409px 447px #fff,
            212px 617px #fff,
            1438px 1766px #fff,
            410px 767px #fff,
            82px 1180px #fff,
            973px 438px #fff,
            1056px 27px #fff,
            1104px 137px #fff,
            889px 1330px #fff,
            1017px 1867px #fff,
            746px 1519px #fff,
            1441px 942px #fff,
            139px 1013px #fff,
            1966px 1812px #fff,
            967px 1057px #fff,
            356px 840px #fff,
            400px 1283px #fff,
            1424px 1306px #fff,
            720px 726px #fff,
            402px 1889px #fff,
            445px 1627px #fff,
            1021px 1535px #fff,
            614px 166px #fff,
            1452px 1021px #fff,
            1292px 1620px #fff,
            1245px 1553px #fff,
            1939px 275px #fff,
            1975px 1462px #fff,
            1865px 210px #fff,
            846px 1994px #fff,
            8px 1539px #fff,
            714px 407px #fff,
            1199px 1367px #fff,
            673px 464px #fff,
            1745px 1811px #fff,
            1140px 1036px #fff,
            899px 1086px #fff,
            1740px 496px #fff,
            1311px 267px #fff,
            1995px 1514px #fff,
            1133px 760px #fff,
            1688px 1836px #fff,
            626px 366px #fff,
            37px 1495px #fff,
            834px 1255px #fff,
            1605px 1505px #fff,
            732px 1912px #fff,
            872px 440px #fff,
            324px 1686px #fff,
            403px 279px #fff,
            1358px 1038px #fff,
            1274px 226px #fff,
            505px 1352px #fff,
            355px 1061px #fff,
            1064px 697px #fff,
            1022px 684px #fff,
            1062px 1742px #fff,
            1007px 993px #fff,
            645px 1324px #fff,
            1348px 549px #fff,
            906px 483px #fff,
            1319px 1472px #fff,
            128px 1454px #fff,
            20px 1360px #fff,
            1189px 1519px #fff,
            1050px 853px #fff,
            1696px 1017px #fff,
            29px 1907px #fff,
            1017px 638px #fff,
            1905px 882px #fff,
            160px 1033px #fff,
            1043px 843px #fff,
            1529px 121px #fff,
            382px 673px #fff,
            1399px 452px #fff,
            1342px 1265px #fff,
            614px 1479px #fff,
            217px 1222px #fff,
            1183px 441px #fff,
            588px 89px #fff,
            899px 1837px #fff,
            1708px 1661px #fff,
            1150px 1770px #fff,
            1296px 1742px #fff,
            848px 1464px #fff,
            1613px 1686px #fff,
            416px 1047px #fff,
            447px 483px #fff,
            307px 1444px #fff,
            1904px 1490px #fff,
            1493px 777px #fff,
            906px 800px #fff,
            1529px 70px #fff,
            846px 456px #fff,
            1250px 1288px #fff,
            767px 245px #fff,
            794px 723px #fff,
            990px 1653px #fff,
            1201px 1952px #fff,
            1815px 537px #fff,
            106px 1958px #fff,
            146px 867px #fff,
            1923px 1731px #fff,
            87px 877px #fff,
            1663px 1769px #fff,
            1233px 228px #fff,
            544px 378px #fff,
            182px 1800px #fff,
            53px 1661px #fff,
            635px 988px #fff,
            1490px 1199px #fff,
            1015px 732px #fff,
            595px 1970px #fff,
            870px 1596px #fff,
            1755px 913px #fff,
            1785px 735px #fff,
            212px 299px #fff,
            915px 1895px #fff,
            1818px 1568px #fff,
            1326px 135px #fff,
            1436px 111px #fff,
            561px 1695px #fff,
            232px 439px #fff,
            1660px 1435px #fff,
            201px 1725px #fff,
            1466px 904px #fff,
            467px 1067px #fff,
            471px 536px #fff,
            685px 705px #fff,
            1911px 315px #fff,
            324px 1050px #fff,
            1412px 26px #fff,
            210px 295px #fff,
            356px 1553px #fff,
            1673px 1275px #fff,
            899px 1252px #fff,
            1630px 630px #fff,
            1538px 984px #fff,
            1330px 1099px #fff,
            1449px 550px #fff,
            807px 881px #fff,
            1344px 1334px #fff,
            1529px 1838px #fff,
            1624px 1320px #fff,
            75px 1206px #fff,
            1261px 1201px #fff,
            1515px 1577px #fff,
            1233px 47px #fff,
            486px 140px #fff,
            1591px 1386px #fff,
            196px 688px #fff,
            855px 1086px #fff,
            538px 1551px #fff,
            1019px 1122px #fff,
            665px 1600px #fff,
            1666px 1979px #fff,
            1329px 557px #fff,
            1213px 1281px #fff,
            1784px 1614px #fff,
            1766px 30px #fff,
            831px 1591px #fff,
            1589px 207px #fff,
            1660px 1151px #fff;
    }

    .stars2 {
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow:
            1037px 1342px #fff,
            18px 261px #fff,
            1361px 975px #fff,
            1760px 687px #fff,
            846px 1551px #fff,
            1070px 740px #fff,
            1671px 25px #fff,
            377px 1908px #fff,
            1652px 246px #fff,
            864px 1187px #fff,
            1467px 1942px #fff,
            1629px 535px #fff,
            1850px 7px #fff,
            781px 146px #fff,
            488px 1312px #fff,
            1920px 1882px #fff,
            893px 1848px #fff,
            1790px 1093px #fff,
            1802px 1342px #fff,
            1455px 1622px #fff,
            305px 1139px #fff,
            546px 1406px #fff,
            37px 350px #fff,
            717px 1900px #fff,
            61px 1980px #fff,
            1301px 216px #fff,
            183px 1880px #fff,
            1930px 1504px #fff,
            1340px 1399px #fff,
            427px 960px #fff,
            478px 287px #fff,
            1765px 587px #fff,
            725px 1301px #fff,
            1609px 247px #fff,
            477px 1303px #fff,
            696px 915px #fff,
            1263px 937px #fff,
            1977px 1330px #fff,
            1384px 234px #fff,
            1705px 689px #fff,
            217px 993px #fff,
            1372px 1869px #fff,
            626px 403px #fff,
            1802px 229px #fff,
            346px 485px #fff,
            1092px 1486px #fff,
            100px 67px #fff,
            573px 753px #fff,
            78px 1507px #fff,
            30px 263px #fff,
            1639px 411px #fff,
            1043px 1494px #fff,
            1864px 253px #fff,
            1350px 1256px #fff,
            1553px 67px #fff,
            737px 27px #fff,
            580px 563px #fff,
            858px 485px #fff,
            1506px 1776px #fff,
            1221px 102px #fff,
            1390px 1232px #fff,
            1231px 929px #fff,
            1788px 869px #fff,
            27px 1747px #fff,
            1547px 985px #fff,
            534px 1350px #fff,
            176px 1376px #fff,
            767px 1212px #fff,
            1113px 17px #fff,
            1520px 39px #fff,
            643px 1839px #fff,
            729px 1869px #fff,
            1871px 765px #fff,
            480px 398px #fff,
            644px 733px #fff,
            421px 1767px #fff,
            800px 1741px #fff,
            309px 115px #fff,
            84px 383px #fff,
            1444px 376px #fff,
            1823px 789px #fff,
            576px 1994px #fff,
            240px 909px #fff,
            1795px 42px #fff,
            802px 548px #fff,
            1687px 1701px #fff,
            1261px 1896px #fff,
            1393px 1515px #fff,
            475px 1467px #fff,
            1882px 150px #fff,
            1451px 695px #fff,
            995px 53px #fff,
            946px 726px #fff,
            1521px 642px #fff,
            608px 832px #fff,
            538px 1587px #fff,
            890px 462px #fff,
            679px 1884px #fff,
            1720px 1019px #fff,
            1235px 1468px #fff,
            1248px 1465px #fff,
            513px 28px #fff,
            1356px 1823px #fff,
            1903px 259px #fff,
            1400px 1060px #fff,
            1485px 440px #fff,
            1786px 1228px #fff,
            565px 426px #fff,
            1064px 1401px #fff,
            1926px 1928px #fff,
            1416px 141px #fff,
            1046px 1133px #fff,
            1025px 1956px #fff,
            1315px 295px #fff,
            1245px 67px #fff,
            1336px 478px #fff,
            847px 1820px #fff,
            1067px 603px #fff,
            1138px 791px #fff,
            627px 950px #fff,
            1336px 507px #fff,
            1284px 375px #fff,
            203px 331px #fff,
            808px 1305px #fff,
            294px 1550px #fff,
            972px 820px #fff,
            213px 1742px #fff,
            795px 979px #fff,
            1364px 1522px #fff,
            944px 784px #fff,
            1199px 708px #fff,
            625px 78px #fff,
            746px 59px #fff,
            1043px 555px #fff,
            153px 104px #fff,
            1778px 389px #fff,
            1468px 290px #fff,
            1347px 897px #fff,
            108px 1420px #fff,
            636px 1079px #fff,
            450px 177px #fff,
            1779px 508px #fff,
            1771px 485px #fff,
            449px 1697px #fff,
            147px 504px #fff,
            1667px 1601px #fff,
            184px 1305px #fff,
            425px 1077px #fff,
            1173px 1135px #fff,
            832px 483px #fff,
            607px 1520px #fff,
            987px 1048px #fff,
            1694px 318px #fff,
            1418px 143px #fff,
            1238px 494px #fff,
            1730px 1086px #fff,
            195px 51px #fff,
            942px 1925px #fff,
            1716px 1385px #fff,
            71px 359px #fff,
            1358px 982px #fff,
            757px 30px #fff,
            471px 153px #fff,
            49px 1650px #fff,
            224px 481px #fff,
            696px 950px #fff,
            278px 629px #fff,
            931px 1637px #fff,
            1381px 99px #fff,
            1579px 115px #fff,
            535px 1269px #fff,
            1452px 1542px #fff,
            1332px 1656px #fff,
            1048px 835px #fff,
            1386px 1867px #fff,
            15px 838px #fff,
            1481px 716px #fff,
            717px 1227px #fff,
            1514px 28px #fff,
            488px 97px #fff,
            794px 1192px #fff,
            925px 529px #fff,
            417px 1612px #fff,
            1047px 1276px #fff,
            1597px 290px #fff,
            652px 749px #fff,
            821px 1818px #fff,
            1565px 644px #fff,
            855px 325px #fff,
            1793px 855px #fff,
            962px 1762px #fff,
            883px 262px #fff,
            1095px 1836px #fff,
            975px 571px #fff,
            846px 848px #fff,
            1378px 250px #fff,
            1454px 790px #fff,
            1699px 1150px #fff,
            89px 1628px #fff,
            741px 282px #fff;
        animation: animStar 100s linear infinite;
    }
    .stars2:after {
        content: " ";
        position: absolute;
        left: -2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow:
            1037px 1342px #fff,
            18px 261px #fff,
            1361px 975px #fff,
            1760px 687px #fff,
            846px 1551px #fff,
            1070px 740px #fff,
            1671px 25px #fff,
            377px 1908px #fff,
            1652px 246px #fff,
            864px 1187px #fff,
            1467px 1942px #fff,
            1629px 535px #fff,
            1850px 7px #fff,
            781px 146px #fff,
            488px 1312px #fff,
            1920px 1882px #fff,
            893px 1848px #fff,
            1790px 1093px #fff,
            1802px 1342px #fff,
            1455px 1622px #fff,
            305px 1139px #fff,
            546px 1406px #fff,
            37px 350px #fff,
            717px 1900px #fff,
            61px 1980px #fff,
            1301px 216px #fff,
            183px 1880px #fff,
            1930px 1504px #fff,
            1340px 1399px #fff,
            427px 960px #fff,
            478px 287px #fff,
            1765px 587px #fff,
            725px 1301px #fff,
            1609px 247px #fff,
            477px 1303px #fff,
            696px 915px #fff,
            1263px 937px #fff,
            1977px 1330px #fff,
            1384px 234px #fff,
            1705px 689px #fff,
            217px 993px #fff,
            1372px 1869px #fff,
            626px 403px #fff,
            1802px 229px #fff,
            346px 485px #fff,
            1092px 1486px #fff,
            100px 67px #fff,
            573px 753px #fff,
            78px 1507px #fff,
            30px 263px #fff,
            1639px 411px #fff,
            1043px 1494px #fff,
            1864px 253px #fff,
            1350px 1256px #fff,
            1553px 67px #fff,
            737px 27px #fff,
            580px 563px #fff,
            858px 485px #fff,
            1506px 1776px #fff,
            1221px 102px #fff,
            1390px 1232px #fff,
            1231px 929px #fff,
            1788px 869px #fff,
            27px 1747px #fff,
            1547px 985px #fff,
            534px 1350px #fff,
            176px 1376px #fff,
            767px 1212px #fff,
            1113px 17px #fff,
            1520px 39px #fff,
            643px 1839px #fff,
            729px 1869px #fff,
            1871px 765px #fff,
            480px 398px #fff,
            644px 733px #fff,
            421px 1767px #fff,
            800px 1741px #fff,
            309px 115px #fff,
            84px 383px #fff,
            1444px 376px #fff,
            1823px 789px #fff,
            576px 1994px #fff,
            240px 909px #fff,
            1795px 42px #fff,
            802px 548px #fff,
            1687px 1701px #fff,
            1261px 1896px #fff,
            1393px 1515px #fff,
            475px 1467px #fff,
            1882px 150px #fff,
            1451px 695px #fff,
            995px 53px #fff,
            946px 726px #fff,
            1521px 642px #fff,
            608px 832px #fff,
            538px 1587px #fff,
            890px 462px #fff,
            679px 1884px #fff,
            1720px 1019px #fff,
            1235px 1468px #fff,
            1248px 1465px #fff,
            513px 28px #fff,
            1356px 1823px #fff,
            1903px 259px #fff,
            1400px 1060px #fff,
            1485px 440px #fff,
            1786px 1228px #fff,
            565px 426px #fff,
            1064px 1401px #fff,
            1926px 1928px #fff,
            1416px 141px #fff,
            1046px 1133px #fff,
            1025px 1956px #fff,
            1315px 295px #fff,
            1245px 67px #fff,
            1336px 478px #fff,
            847px 1820px #fff,
            1067px 603px #fff,
            1138px 791px #fff,
            627px 950px #fff,
            1336px 507px #fff,
            1284px 375px #fff,
            203px 331px #fff,
            808px 1305px #fff,
            294px 1550px #fff,
            972px 820px #fff,
            213px 1742px #fff,
            795px 979px #fff,
            1364px 1522px #fff,
            944px 784px #fff,
            1199px 708px #fff,
            625px 78px #fff,
            746px 59px #fff,
            1043px 555px #fff,
            153px 104px #fff,
            1778px 389px #fff,
            1468px 290px #fff,
            1347px 897px #fff,
            108px 1420px #fff,
            636px 1079px #fff,
            450px 177px #fff,
            1779px 508px #fff,
            1771px 485px #fff,
            449px 1697px #fff,
            147px 504px #fff,
            1667px 1601px #fff,
            184px 1305px #fff,
            425px 1077px #fff,
            1173px 1135px #fff,
            832px 483px #fff,
            607px 1520px #fff,
            987px 1048px #fff,
            1694px 318px #fff,
            1418px 143px #fff,
            1238px 494px #fff,
            1730px 1086px #fff,
            195px 51px #fff,
            942px 1925px #fff,
            1716px 1385px #fff,
            71px 359px #fff,
            1358px 982px #fff,
            757px 30px #fff,
            471px 153px #fff,
            49px 1650px #fff,
            224px 481px #fff,
            696px 950px #fff,
            278px 629px #fff,
            931px 1637px #fff,
            1381px 99px #fff,
            1579px 115px #fff,
            535px 1269px #fff,
            1452px 1542px #fff,
            1332px 1656px #fff,
            1048px 835px #fff,
            1386px 1867px #fff,
            15px 838px #fff,
            1481px 716px #fff,
            717px 1227px #fff,
            1514px 28px #fff,
            488px 97px #fff,
            794px 1192px #fff,
            925px 529px #fff,
            417px 1612px #fff,
            1047px 1276px #fff,
            1597px 290px #fff,
            652px 749px #fff,
            821px 1818px #fff,
            1565px 644px #fff,
            855px 325px #fff,
            1793px 855px #fff,
            962px 1762px #fff,
            883px 262px #fff,
            1095px 1836px #fff,
            975px 571px #fff,
            846px 848px #fff,
            1378px 250px #fff,
            1454px 790px #fff,
            1699px 1150px #fff,
            89px 1628px #fff,
            741px 282px #fff;
    }

    .stars3 {
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow:
            402px 1645px #fff,
            550px 643px #fff,
            634px 725px #fff,
            1715px 1562px #fff,
            1981px 1501px #fff,
            1605px 878px #fff,
            1690px 1007px #fff,
            416px 1068px #fff,
            522px 1469px #fff,
            1811px 714px #fff,
            1584px 816px #fff,
            1560px 1492px #fff,
            1281px 1996px #fff,
            1341px 1602px #fff,
            974px 1758px #fff,
            1174px 424px #fff,
            1705px 7px #fff,
            945px 1080px #fff,
            274px 1885px #fff,
            512px 1679px #fff,
            572px 1110px #fff,
            666px 1073px #fff,
            1171px 95px #fff,
            697px 1877px #fff,
            1136px 513px #fff,
            552px 1434px #fff,
            1182px 529px #fff,
            375px 113px #fff,
            86px 161px #fff,
            1510px 252px #fff,
            1283px 14px #fff,
            210px 1581px #fff,
            1619px 1470px #fff,
            178px 334px #fff,
            1603px 73px #fff,
            1664px 1924px #fff,
            787px 1737px #fff,
            1437px 1178px #fff,
            668px 1824px #fff,
            921px 1665px #fff,
            1348px 1428px #fff,
            1326px 56px #fff,
            1809px 1008px #fff,
            1996px 819px #fff,
            1071px 726px #fff,
            134px 94px #fff,
            1079px 543px #fff,
            486px 1519px #fff,
            87px 1597px #fff,
            450px 582px #fff,
            697px 948px #fff,
            537px 1900px #fff,
            709px 42px #fff,
            1935px 1062px #fff,
            1335px 1343px #fff,
            823px 1411px #fff,
            321px 379px #fff,
            956px 683px #fff,
            662px 1992px #fff,
            1231px 1749px #fff,
            1398px 1743px #fff,
            292px 1513px #fff,
            1727px 66px #fff,
            487px 142px #fff,
            1602px 171px #fff,
            565px 1748px #fff,
            1755px 1997px #fff,
            1858px 493px #fff,
            154px 1205px #fff,
            1772px 190px #fff,
            892px 1669px #fff,
            1378px 546px #fff,
            939px 390px #fff,
            1417px 775px #fff,
            934px 1726px #fff,
            1666px 1950px #fff,
            1645px 1377px #fff,
            1630px 1518px #fff,
            1516px 889px #fff,
            1034px 1997px #fff,
            278px 1953px #fff,
            473px 267px #fff,
            203px 397px #fff,
            361px 612px #fff,
            1863px 1403px #fff,
            1430px 1150px #fff,
            447px 340px #fff,
            1943px 946px #fff,
            1301px 831px #fff,
            1904px 584px #fff,
            198px 637px #fff,
            906px 283px #fff,
            1671px 18px #fff,
            1741px 1914px #fff,
            612px 833px #fff,
            1149px 1874px #fff,
            97px 1814px #fff,
            1613px 1466px #fff,
            496px 724px #fff,
            768px 1216px #fff;
        animation: animStar 150s linear infinite;
    }
    .stars3:after {
        content: " ";
        position: absolute;
        left: -2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow:
            402px 1645px #fff,
            550px 643px #fff,
            634px 725px #fff,
            1715px 1562px #fff,
            1981px 1501px #fff,
            1605px 878px #fff,
            1690px 1007px #fff,
            416px 1068px #fff,
            522px 1469px #fff,
            1811px 714px #fff,
            1584px 816px #fff,
            1560px 1492px #fff,
            1281px 1996px #fff,
            1341px 1602px #fff,
            974px 1758px #fff,
            1174px 424px #fff,
            1705px 7px #fff,
            945px 1080px #fff,
            274px 1885px #fff,
            512px 1679px #fff,
            572px 1110px #fff,
            666px 1073px #fff,
            1171px 95px #fff,
            697px 1877px #fff,
            1136px 513px #fff,
            552px 1434px #fff,
            1182px 529px #fff,
            375px 113px #fff,
            86px 161px #fff,
            1510px 252px #fff,
            1283px 14px #fff,
            210px 1581px #fff,
            1619px 1470px #fff,
            178px 334px #fff,
            1603px 73px #fff,
            1664px 1924px #fff,
            787px 1737px #fff,
            1437px 1178px #fff,
            668px 1824px #fff,
            921px 1665px #fff,
            1348px 1428px #fff,
            1326px 56px #fff,
            1809px 1008px #fff,
            1996px 819px #fff,
            1071px 726px #fff,
            134px 94px #fff,
            1079px 543px #fff,
            486px 1519px #fff,
            87px 1597px #fff,
            450px 582px #fff,
            697px 948px #fff,
            537px 1900px #fff,
            709px 42px #fff,
            1935px 1062px #fff,
            1335px 1343px #fff,
            823px 1411px #fff,
            321px 379px #fff,
            956px 683px #fff,
            662px 1992px #fff,
            1231px 1749px #fff,
            1398px 1743px #fff,
            292px 1513px #fff,
            1727px 66px #fff,
            487px 142px #fff,
            1602px 171px #fff,
            565px 1748px #fff,
            1755px 1997px #fff,
            1858px 493px #fff,
            154px 1205px #fff,
            1772px 190px #fff,
            892px 1669px #fff,
            1378px 546px #fff,
            939px 390px #fff,
            1417px 775px #fff,
            934px 1726px #fff,
            1666px 1950px #fff,
            1645px 1377px #fff,
            1630px 1518px #fff,
            1516px 889px #fff,
            1034px 1997px #fff,
            278px 1953px #fff,
            473px 267px #fff,
            203px 397px #fff,
            361px 612px #fff,
            1863px 1403px #fff,
            1430px 1150px #fff,
            447px 340px #fff,
            1943px 946px #fff,
            1301px 831px #fff,
            1904px 584px #fff,
            198px 637px #fff,
            906px 283px #fff,
            1671px 18px #fff,
            1741px 1914px #fff,
            612px 833px #fff,
            1149px 1874px #fff,
            97px 1814px #fff,
            1613px 1466px #fff,
            496px 724px #fff,
            768px 1216px #fff;
    }

    @keyframes animStar {
        0% {
            transform: translateX(0px);
        }
        100% {
            transform: translateX(2000px);
        }
    }
}
