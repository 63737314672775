.list_in_bulk_nft {
    .nft_content {
        min-width: 800px;
        height: 500px;
        display: flex;
        gap: 60px;
        padding: 30px;
        box-sizing: border-box;
        overflow: hidden;

        .nft_input {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            .input_title {
                color: #2e2139;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .input_content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;

                .button {
                    padding: 0;
                    overflow: hidden;
                }

                .dropdown {
                    min-width: 120px;
                    max-width: 120px;
                    padding: 0 12px;
                }
            }

            .dropdown,
            .button {
                height: 40px;
                border-radius: 12px;
                border: 1px solid #2e2139;
                background: #fff;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                box-sizing: border-box;
                color: #2e2139;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .nft_content_left {
        height: 470px;
        width: 972px;
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;

        .nft_list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 99%;
            height: auto;
            filter: drop-shadow(3px 3px 1px rgba(124, 29, 201, 0.7));

            .nft_detail {
                background-color: #fff;
                border-radius: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 109px;
                margin-bottom: 5px;
                padding: 0 20px;
                box-sizing: border-box;

                .nft_right {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    justify-content: space-between;

                    .nft_img {
                        width: 68px;
                        height: 68px;
                        border-radius: 11px;
                    }

                    .nft_series {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        flex-direction: column;
                        color: #7650ca;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 36px;

                        .series {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            color: #2e2139;
                            font-family: "Noto Sans";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 32px;
                        }
                    }
                }

                .nft_item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-end;
                    height: 50%;

                    .nft_item_title {
                        color: #2e2139;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        width: 160px;
                        text-align: center;
                    }

                    .nft_item_content {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #7c1dc9;
                        text-align: right;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                .nft_input {
                    max-width: 300px;
                }
            }
        }
    }

    .nft_content_right {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        width: 300px;
        gap: 48px;
        height: 470px;

        .buy_fee {
            .tit {
                color: #430774;
                font-family: "Noto Sans";
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
            }
            .tit_content {
                color: #430774;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
            .fee {
                display: flex;
                gap: 50px;
                align-items: center;
                color: #721ac9;
                text-align: right;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .nft_ear {
            color: #721ac9;
            font-family: "Noto Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .nft_message {
        height: 50px;
        display: flex;
        align-items: center;
        gap: 10px;

        .message_title {
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
        }

        .message_success {
            color: #2e2139;
        }

        .message_filed {
            color: #f00;
        }
    }

    .nft_button {
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        gap: 40px;
        justify-content: center;

        .button_common {
            font-size: 24px;
            height: 65px;
        }
    }
}
