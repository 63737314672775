#root {
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 560px;
        width: 600px;
        justify-content: space-between;
        padding-top: 30px;
        box-sizing: border-box;
        gap: 20px;
        z-index: 1;

        // layout
        .form_space_between {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .form_space_evenly {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
        }
        .form_flex_column {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        .form_flex_end {
            display: flex;
            align-items: flex-end;
        }

        .form_input_lock {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            line-height: 65px;
            border-radius: 16px;
            border: 2px solid rgba(255, 255, 255, 0.15);
            background: #40077e;
            background-size: 100% 100%;
            position: relative;
            font-family: "Noto Sans";
            font-weight: normal;
            color: #9f9f9f;
            font-size: 24px;
            padding: 0 0 0 10px;
            box-sizing: border-box;
            cursor: no-drop;
        }

        // form link
        .form_link {
            font-size: 16px;
            font-family: "Noto Sans";
            font-weight: normal;
            text-decoration: underline;
            color: #ffffff;
            line-height: 50px;
            cursor: pointer;
        }

        // form title
        .web_title {
            width: 100%;
            box-sizing: border-box;
        }

        .form_subtitle {
            font-size: 24px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: rgba(255, 255, 255, 0.79);
            line-height: 50px;
        }
        // get code suffix
        .suffix_code_father {
            .suffix_code {
                width: 270px;
                padding: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #ffffff;
                cursor: pointer;
                border-radius: 0px 16px 16px 0px;
                background: #8558b9;
                border: none;
            }
            .disabled {
                cursor: no-drop;
                color: rgba(255, 255, 255, 0.5);
            }
        }

        // checkbox
        .form_checkbox {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            color: #fff;
            font-family: "Noto Sans";
            font-size: 18px;
            font-weight: 400;
        }

        // button
        .button_common {
            font-size: 24px;
            height: 65px;
        }

        // Login suffix ==> Forgot password
        .forgot_suffix_father {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .forgot_suffix {
                position: absolute;
                right: 40px;
                top: 0;
                bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
                width: 140px;
            }
        }
        // prefix recover code
        .recover_code_prefix {
            font-size: 24px;
            font-family: "Noto Sans";
            font-weight: normal;
            color: rgba(255, 255, 255, 0.61);
            line-height: 50px;
        }
    }
}
.web {
    background: linear-gradient(180deg, #677aab 0%, #8562d4 100%);
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
        width: 520px;
        height: 280px;
        position: relative;
        top: 30px;
        background: url(../../assets/icon/logo.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 1;
    }
}
