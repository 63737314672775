.create_room {
    display: flex;
    position: relative;
    width: 1816px;
    height: 855px;
    border-radius: 35px;
    border: 10px solid #68568c;
    background: linear-gradient(180deg, #756e9f 0%, #605a86 100%);
    box-shadow: 0px 10px 3px 0px rgba(106, 98, 109, 0.4);
    box-sizing: border-box;
    padding: 18px;

    .room_header {
        display: flex;
        width: 1690px;
        height: 99px;
        align-items: center;
        justify-content: space-between;
        border-radius: 35px 35px 0px 0px;
        background: linear-gradient(90deg, #9371cc 0%, #b7aaf8 53.5%, #a798e0 100%);

        .user_msg {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 50px;
            width: 850px;
            margin-left: 46px;

            .title_title {
                height: 100%;
                font-size: 48px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #fff;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                border-color: transparent;
            }
            .input_title {
                background-color: transparent;
                height: 100%;
                font-size: 48px;
                font-family: "Noto Sans";
                font-weight: normal;
                color: #fff;
                border: none;
                border-color: transparent;
                box-shadow: none;
            }
            .title_suffix {
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: left;
                height: 100%;
                min-width: 200px;

                .title_score {
                    line-height: 50px;
                    font-size: 36px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #0b4670;
                    text-shadow: none;

                    span {
                        text-decoration: underline;
                    }
                }
            }
            .button_save {
                height: 42px;
                border-radius: 15px;
                background: linear-gradient(183deg, #31d92f 2.81%, #6adb6a 97.41%);
                box-shadow: 0px 5px 0px 0px #7a67b4;
                color: #fff;
                font-family: "Noto Sans";
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
            }
            .button_cancel {
                height: 42px;
                border-radius: 15px;
                background: linear-gradient(183deg, #c51010 2.81%, #e43131 97.41%);
                box-shadow: 0px 5px 0px 0px #7a67b4;
                color: #fff;
                font-family: "Noto Sans";
                font-size: 21px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
            }
        }

        .inter {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;
            margin: 0 46px 0 0;

            .inter_link {
                height: 50px;
                width: 424px;
                line-height: 48px;
                font-size: 21px;
                font-family: "Noto Sans";
                color: #fff;
                font-weight: normal;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-radius: 15px;
                background: linear-gradient(183deg, #8a76f2 2.81%, #9fa7fb 97.41%);
                box-shadow: 0px 5px 0px 0px #7a67b4;
            }
        }
    }

    .room_content {
        position: absolute;
        width: 1760px;
        height: 700px;
        flex-shrink: 0;
        top: 117px;
        border-radius: 0px 35px 35px 35px;
        background: #ffe1ff;
        padding: 30px 30px 0 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .competitive_seats {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .username {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }

            .host {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                height: 435px;
                width: 685px;

                .host_title {
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 42px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .avatar {
                    position: relative;

                    .av {
                        width: 305px;
                        height: 305px;
                        border-radius: 50%;
                        box-shadow: 0px 5px 3px 0px rgba(118, 80, 202, 0.4);
                        overflow: hidden;
                    }
                }

                .username {
                    color: #2e2139;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .competitive_seats_right {
                position: relative;

                .competitive_number {
                    position: absolute;
                    color: #fefefe;
                    font-family: "Noto Sans";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    top: 10px;
                    right: 20px;
                }

                .members {
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    width: 1044px;
                    height: 435px;
                    border-radius: 13px;
                    background: #7650ca;
                    padding: 30px 58px 30px 58px;
                    gap: 20px 85px;

                    .item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        box-sizing: border-box;
                        gap: 10px;

                        .avatar {
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            overflow: hidden;
                            box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
                            background: rgba(180, 180, 180, 0.8);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .locked_ic {
                                display: none;
                            }
                            .unlocked_ic {
                                display: none;
                            }
                        }

                        .avatar:hover {
                            .locked_ic {
                                display: block;
                            }
                            .unlocked_ic {
                                display: block;
                            }
                            .accumulate_ic_none {
                                display: none;
                            }
                            .decumulate_ic_none {
                                display: none;
                            }
                        }
                        .username {
                            color: #fff;
                            text-align: center;
                            font-family: "Noto Sans";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px; /* 100% */
                        }
                    }
                }
                .button_list {
                    display: flex;
                    align-items: center;
                    gap: 50px;
                    width: 100%;
                    padding-top: 26px;
                    padding-bottom: 34px;
                    box-sizing: border-box;

                    button {
                        box-sizing: border-box;
                        display: inline-flex;
                        padding: 15px;
                        align-items: flex-start;
                        gap: 10px;
                        width: auto;
                        height: 56px;
                        font-size: 16px;
                        font-family: "Noto Sans";
                        font-weight: normal;
                        color: #ffffff;
                        border: none;
                        cursor: pointer;
                        border-radius: 25px;
                        box-shadow:
                            4px 38px 62px 0px rgba(0, 0, 0, 0.5),
                            -3px -4px 7px 0px rgba(255, 255, 255, 0.15) inset;
                    }

                    .button_yellow {
                        background: radial-gradient(
                                76.16% 76.16% at 31.97% 19.67%,
                                rgba(255, 255, 255, 0.7) 0%,
                                rgba(255, 255, 255, 0) 69.79%,
                                rgba(255, 255, 255, 0) 100%
                            ),
                            #df9800;
                    }

                    .button_purple {
                        border-radius: 25px;
                        background: radial-gradient(
                                76.16% 76.16% at 31.97% 19.67%,
                                rgba(255, 255, 255, 0.7) 0%,
                                rgba(255, 255, 255, 0) 69.79%,
                                rgba(255, 255, 255, 0) 100%
                            ),
                            #721ac9;
                    }

                    .button_red {
                        border-radius: 25px;
                        background: radial-gradient(
                                76.16% 76.16% at 31.97% 19.67%,
                                rgba(255, 255, 255, 0.7) 0%,
                                rgba(255, 255, 255, 0) 69.79%,
                                rgba(255, 255, 255, 0) 100%
                            ),
                            #c51010;
                    }

                    .disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }

        .spectator_seats {
            box-sizing: border-box;
            width: calc(100% - 30px);
            margin-left: 30px;
            border-radius: 13px 13px 0 0;
            background: #7650ca;
            height: 120px;
            display: flex;
            align-items: center;
            gap: 50px;
            padding: 0 40px;

            .content_title {
                color: #fff;
                font-family: "Noto Sans";
                font-size: 42px;
                font-weight: 400;
                line-height: 120px;
            }

            .spectator {
                width: 1350px;
                height: 110px;
                overflow: hidden;

                .item {
                    display: inline-block;
                    margin-right: 25px;
                    width: 80px;
                    height: 110px;
                    overflow: hidden;
                    .avatar {
                        border-radius: 50%;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
                        background: rgba(180, 180, 180, 0.8);
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .username {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: center;
                        color: #fff;
                        text-align: center;
                        font-family: "Noto Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px; /* 100% */
                    }
                }
            }
        }
    }

    .edit_ic {
        background: url("../../assets/hall/edit_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 60px;
        height: 60px;
        cursor: pointer;
    }

    .copy_ic {
        background: url("../../assets/hall/copy_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 60px;
        height: 60px;
        cursor: pointer;
    }

    .accumulate_ic,
    .accumulate_ic_none {
        background: url("../../assets/hall/accumulate_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 55px;
        height: 55px;
        box-shadow:
            0px 25px 55px 0px rgba(0, 0, 0, 0.8),
            3px 4px 7px 2px rgba(255, 255, 255, 0.8) inset;
        border-radius: 50%;
        cursor: pointer;
    }

    .decumulate_ic,
    .decumulate_ic_none {
        background: url("../../assets/hall/decumulate_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 55px;
        height: 55px;
        box-shadow:
            0px 25px 55px 0px rgba(0, 0, 0, 0.8),
            3px 4px 7px 2px rgba(255, 255, 255, 0.8) inset;
        border-radius: 50%;
        cursor: pointer;
    }

    .locked_ic {
        background: url("../../assets/hall/locked_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 55px;
        height: 55px;
        cursor: pointer;
    }

    .unlocked_ic {
        background: url("../../assets/hall/unlocked_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 55px;
        height: 55px;
        cursor: pointer;
    }

    .startup_ic {
        background: url("../../assets/hall/startup_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .watch_ic {
        background: url("../../assets/hall/watch_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .exit_ic {
        background: url("../../assets/hall/exit_ic.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}
