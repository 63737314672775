.ticker-wrapper {
    display: flex;
    justify-content: center;
    gap: 150px;
    width: 100%;
    height: 100%;
    margin-bottom: 52px;

    .top {
        background-image: url("../../../assets/hall/ticker_bg.png");
        background-size: cover;
        width: 470px;
        height: 430px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 44px;
        position: relative;
        border-radius: 20px;

        .ticker-type {
            position: absolute;
            top: -28px;
            width: 298px;
            height: 53px;
            line-height: 53px;
            text-align: center;
            border-radius: 33px;
            border: 2px solid #fff;
            background: #904ad4;
            color: #fff;
            font-size: 24px;
            font-family: "Noto Sans";
            font-weight: 700;
        }
        .ticker-img {
            background-image: url("../../../assets/hall/ticket.png");
            background-size: cover;
            width: 218px;
            height: 261px;
            margin-top: 44px;
        }
        .ticker-img-free {
            background-image: url("../../../assets/hall/ticket.png");
            background-size: cover;
            width: 300px;
            height: 350px;
            margin-top: 44px;
        }
        .buttom {
            position: absolute;
            bottom: 0;
            width: 100%;
            border-radius: 0 0 20px 20px;
            background: linear-gradient(99deg, #904ad4 31.84%, #40077e 167.75%);
            box-shadow: -5px -5px 5px 0px rgba(60, 43, 74, 0.3);
            height: 141px;
            display: flex;
            flex-direction: column;
            // justify-content: center;

            .total {
                color: #fff;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 38px;
                font-weight: 900;
                line-height: 70px;
            }

            .option {
                display: flex;
                gap: 60px;
                justify-content: center;
                align-items: center;
                .count {
                    color: #fff;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: normal;
                }
                .arrow_button {
                    height: 42px;
                }
            }
        }
    }

    .button_common {
        font-size: 24px;
        height: 65px;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }
}
