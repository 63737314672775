.connect_wallet_dialog {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    z-index: 100;

    .avatar {
        height: 95.156px;
        width: 159.505px;
        flex-shrink: 0;
        overflow: hidden;
        background: url("../../assets/hall/titan_wallet_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .msg {
        color: #40077e;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 20%;
    }

    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        gap: 130px;

        .button_common {
            height: 65px;
            font-size: 24px;
        }
    }
}
