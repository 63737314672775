.settings_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 825px;

    .user_custom_key {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 65px;

        .keys_box {
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .keys_container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .all_key {
                    width: 58px;
                    height: 58px;
                    border-radius: 15px;
                    border: 1px solid #ffffff;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    background: #8160ab;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    font-size: 24px;
                }

                .keys_text {
                    color: #8160ab;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                    margin-top: 5px;
                }

                .space_key {
                    width: 87%;
                }
            }
        }
    }
}

.opt_btn {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .button_common {
        font-size: 24px;
        text-transform: uppercase;
        height: 65px;
    }
}

.opt_btn_center {
    justify-content: center;
}

.setting_list {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0 68px 0;
    gap: 12px;
    font-family: "Noto Sans";

    .setting {
        width: 700px;
        height: 58px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 28px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: #40077e;
        box-shadow: 5px 5px 0px 0px #c2aede;
        font-size: 24px;
        font-weight: normal;
        color: #ffffff;

        .setting_switch {
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;

            .language {
                color: #8a8a8a;
                font-size: 18px;
                font-weight: 500;
            }

            .switch {
                display: none;
            }
            .arrow_button {
                height: 22px;
                .ant-image {
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
