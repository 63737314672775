.header-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;

    .logo {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
            position: relative;
            bottom: -10px;
            width: 250px;
        }
    }

    .header_left {
        display: flex;
        gap: 45px;
        align-items: center;
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
        border-radius: 20px;
        background: linear-gradient(180deg, #fff 0%, #e8dbfb 100%);
        box-shadow: 4px 4px 0px 0px #8582c7;
        min-width: 100px;
        width: auto;
        height: 47px;
        font-size: 18px;
        font-family: "Noto Sans";
        font-weight: 600;
        color: #40077e;
        cursor: pointer;
        text-shadow: none;
        box-sizing: border-box;
        gap: 8px;

        img {
            height: 42px;
        }
    }

    .button:hover {
        opacity: 0.9;
        transform: scale(1.05);
    }
    .button_active {
        background: linear-gradient(180deg, #8582c7 0%, #d0b6ee 100%);
    }

    .wallet-container {
        width: 220px;
        box-sizing: border-box;
        height: 60px;
        flex-shrink: 0;
        border-radius: 20px;
        background: linear-gradient(180deg, #503666 0%, #7c1dc9 149%);
        box-shadow: 3px 3px 3px 0px rgba(73, 75, 134, 0.3);
        color: #fff;
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: none;
        padding: 0 10px;
        cursor: pointer;

        .ant-image {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .text {
            letter-spacing: 0;
            width: 185px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
        }
    }
    .wallet-container:hover {
        color: #ffffff !important;
        background: linear-gradient(180deg, #503666 0%, #7c1dc9 149%) !important;
    }
}
