.dialog-invite-friends {
    min-width: 800px;
    height: 530px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    .msg {
        width: 100%;
        height: 35px;
        font-size: 26px;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #52538b;
        line-height: 50px;
        text-align: center;
    }
    .item_content {
        width: 900px;
        height: 71px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 29px;
        box-sizing: border-box;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: #40077e;

        color: #fff;
        font-family: "Noto Sans";
        font-size: 24px;
        font-weight: 500;

        .item_content_content {
            max-width: 800px;
            //超出一行省略号
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .button_common {
        font-size: 24px;
        height: 65px;
    }
}
