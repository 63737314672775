.income_records_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table_div {
        height: 615px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 20px;
    }

    .filter {
        width: 100%;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #4f1a90;

        .filters {
            gap: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .filter_label {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #4f1a90;
        font-family: "Noto Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
