.level-select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1397px;
    height: 634.5px;

    .level_select_bg {
        position: absolute;
        width: 1517px;
        height: 634.5px;
        background-image: url("../../../assets/hall/level_list_bg.png");
        background-size: cover;
        border-radius: 0 0 50px 50px;
        overflow: hidden;
        top: 0;
        right: -60px;
    }

    .top {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 480px;
        gap: 12%;

        .item-bg {
            display: flex;
            width: 61px;
            height: 121px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;

            .text {
                color: #3f1179;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                text-transform: uppercase;
                position: absolute;
                left: 65px;
                width: 180px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }

        @-webkit-keyframes item-bg {
            16.65% {
                -webkit-transform: translateY(8px);
                transform: translateY(8px);
            }
            33.3% {
                -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
            }
            49.95% {
                -webkit-transform: translateY(4px);
                transform: translateY(4px);
            }
            66.6% {
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
            83.25% {
                -webkit-transform: translateY(1px);
                transform: translateY(1px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }
        @keyframes item-bg {
            16.65% {
                -webkit-transform: translateY(8px);
                transform: translateY(8px);
            }
            33.3% {
                -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
            }
            49.95% {
                -webkit-transform: translateY(4px);
                transform: translateY(4px);
            }
            66.6% {
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
            83.25% {
                -webkit-transform: translateY(1px);
                transform: translateY(1px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }
        .item-bg {
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        }
        .item-bg:hover,
        .item-bg:focus,
        .item-bg:active {
            -webkit-animation-name: item-bg;
            animation-name: item-bg;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
        }

        .item-bg1 {
            background-image: url("../../../assets/hall/level1.png");
            background-size: cover;
        }
        .item-bg2 {
            background-image: url("../../../assets/hall/level2.png");
            background-size: cover;
        }
        .item-lock-bg {
            display: flex;
            background-image: url("../../../assets/hall/level_lock_bg.png");
            background-size: cover;
            width: 66px;
            height: 98px;
            justify-content: center;
            align-items: center;
            cursor: no-drop;
            position: relative;
            top: 55px;
            left: 80px;
            .text {
                color: rgba(255, 255, 255, 0.9);
                text-align: center;
                font-family: "Noto Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                text-transform: uppercase;
                position: absolute;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                left: 70px;
                width: 200px;
                height: 100%;
            }
        }
        .item1 {
            top: 60px;
            left: 140px;
        }
        .item2 {
            top: 10px;
            left: 140px;
        }
        .item3 {
            top: 50px;
            left: 140px;
        }
        .item4 {
            top: 20px;
            left: 180px;
        }
        .item5 {
            top: 60px;
            left: 140px;
        }
        .item6 {
            top: 70px;
            left: 150px;
        }
        .item7 {
            top: 60px;
            left: 140px;
        }
        .item8 {
            top: -10px;
            left: 140px;
        }
        .item9 {
            top: 20px;
            left: 140px;
        }
        .item10 {
            top: -20px;
            left: 160px;
        }
        .item11 {
            top: 40px;
            left: 130px;
        }
        .item12 {
            top: 40px;
            left: 130px;
        }
    }

    .bbb {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 9px;
        margin-top: 23px;

        .point {
            color: #2a005e;
            text-align: center;
            font-family: "Noto Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .button_common {
            height: 65px;
            font-size: 24px;
        }
    }
}

.hero-select-wrapper {
    display: flex;
    align-items: center;
    width: 1715px;
    height: 826px;
    gap: 60px;

    .hero_left {
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        .avatar {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            overflow: hidden;
        }
        .tags_common {
            width: 148px;
            font-size: 16px;
            height: 32px;

            .red {
                color: #f62901;
            }
        }
    }

    .hero_right {
        display: flex;
        flex-direction: column;
        width: 1550px;
        height: 826px;

        .page_div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid #4f1a90;
            height: 70px;
        }

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #4f1a90;

            .middle {
                width: 653px;
                height: 318px;
                display: flex;
                align-items: center;
                gap: 24px;
                scrollbar-width: none;
                -ms-overflow-style: none;
                overflow-x: scroll;
                overflow-y: hidden;

                .item1 {
                    width: 200px;
                    // height: 273px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 0.5);
                    overflow: hidden;

                    .img {
                        width: 200px;
                        height: 200px;
                    }

                    .nftid {
                        color: #6e41d1;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 6px 12px;
                    }

                    .nftpoint {
                        font-family: "Noto Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 6px 12px;
                        border: none;
                    }
                }
            }
            .right {
                height: 318px;
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding-top: 24px;
                box-sizing: border-box;

                .button {
                    display: flex;
                    min-width: 146px;
                    padding: 10px 28px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 20px;
                    background: linear-gradient(178deg, #4f1a90 5.68%, #8428f4 98.16%);
                    box-shadow: 3px 3px 0px 0px #541c9b;
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .disabled {
                    cursor: no-drop;
                    background: linear-gradient(178deg, #989898 5.68%, #e3e2e4 98.16%);
                    box-shadow: 3px 3px 0px 0px #a09fa0;
                }
            }
        }

        .buttom {
            border-top: 1px solid #4f1a90;
            display: flex;
            flex-wrap: wrap;
            gap: 35px;
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 36px;
            box-sizing: border-box;

            .item2 {
                width: 100px;
                height: 173px;
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.5);
                overflow: hidden;
                cursor: pointer;

                .img {
                    width: 100px;
                    height: 100px;
                }

                .nftid {
                    color: #6e41d1;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 6px 12px;
                }

                .nftpoint {
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 6px 12px;
                    border: none;
                }
            }

            .item2:hover {
                background: rgba(255, 255, 255, 0.8);
            }
        }
    }
}
