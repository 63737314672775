#common_pagination {
    height: 40px;
    border-radius: 26px;
    background: #bfa6db;

    :global .ant-pagination {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-pagination-item,
        li {
            height: 30px;
            font-size: 12px;
            line-height: 30px;
            min-width: 30px;
            border-radius: 50%;
            border: none;
            margin: 0 5px;

            a {
                color: #fff;
            }
        }

        .ant-pagination-item-active {
            background-color: #4f1a90;
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            width: 8px;
            height: 100%;

            svg {
                width: 8px;
                height: 100%;
            }
        }

        .ant-pagination-disabled svg {
            fill-opacity: 0.3;
        }
    }
}
