#Message {
    position: absolute;
    right: 24px;
    height: 30vh;
    overflow: hidden;
    width: 42vh;
    bottom: 2vh;

    .ant-notification {
        height: auto;
        width: auto;
        max-height: 30vh;
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        vertical-align: bottom;
        margin-right: 0;
        bottom: 0 !important;

        .ant-notification-notice-wrapper {
            margin: 1vh !important;
        }

        .mint_message {
            border-radius: 12px;
            border: 0.3vh solid #fff;
            background: #0a1f34;
            margin: 0 !important;
            padding: 0 !important;
            width: 40vh !important;

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                padding-inline-end: 0;
                font-weight: normal;
                color: #ffffff;
                padding: 0.5em 5em 1em 5em !important;
                font-weight: 200;
                margin: 0 !important;
                text-align: center;
            }

            .ant-notification-notice-message {
                text-align: center;
                font-weight: 400;
                padding: 1em 5em 0 5em !important;
            }

            .successIcon {
                width: 1.5em;
                height: 1.5em;
                background: url(../../assets/hall/success_icon.png) no-repeat;
                background-size: 100%;
                background-position: center center;
            }

            .failedIcon {
                width: 1.5em;
                height: 1.5em;
                background: url(../../assets/hall/failed_icon.png) no-repeat;
                background-size: 100%;
                background-position: center center;
            }

            .mintingIcon {
                width: 1.5em;
                height: 1.5em;
                background: url(../../assets/hall/minting_icon.png) no-repeat;
                background-size: 100%;
                background-position: center center;
            }

            .closeIcon {
                width: 100%;
                height: 100%;
                background: url(../../assets/hall/messageCloseIcon.png) no-repeat;
                background-size: 100%;
                background-position: center center;
            }

            .ant-notification-notice-icon {
                height: 100%;
                width: 3em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
