.event_promotion_popup {
    background: rgba(65, 8, 127, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .event_promotion_popup_bg {
        width: 1444px;
        height: 976px;
        max-height: 90vh;
        position: relative;
        background-image: url("../../assets/web/event_promotion_img.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        justify-content: flex-end;

        .logo {
            width: 408px;
            height: 220px;
            position: absolute;
            top: 30px;
            background: url(../../assets/icon/logo.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 1;
            top: 50px;
            left: 40px;
        }

        .close_button {
            position: absolute;
            top: 65px;
            right: 65px;
            z-index: 1;
            background-color: transparent;
            border: none;
            border-radius: 15px;
            width: 50px;
            height: 50px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            aspect-ratio: 1 / 1;
            cursor: pointer;
            :global .ant-image {
                width: 50%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .right {
            width: 691px;
            height: 976px;
            max-height: 90vh;
            position: relative;

            .right_bg {
                width: 691px;
                height: 976px;
                max-height: 90vh;
                flex-shrink: 0;
                background: rgba(5, 5, 5, 0.8);
                backdrop-filter: blur(20px);
            }

            .right_content {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                gap: 10px;
                top: 0;

                .title {
                    color: #fff;
                    text-align: center;
                    font-family: "Turret Road";
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .subtitle {
                    color: #fff;
                    text-align: center;
                    font-family: "Titillium Web";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 30px;
                }

                .content {
                    color: #fff;
                    text-align: center;
                    font-family: "Turret Road";
                    font-size: 55px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    white-space: pre-line;
                }

                .content_limited {
                    width: 100%;
                    margin: 70px 0px;
                    // border: 1px solid #fff;
                    :global {
                        .M-Flipper {
                            border-radius: 16px;
                            background: #fff;
                            box-shadow: 5px 5px 5px 0px rgba(237, 237, 237, 0.25);
                            border: none;
                            color: #4c1c8d;
                        }

                        .M-Flipper .digital:before,
                        .M-Flipper .digital:after {
                            background: #fff;
                        }
                    }
                }

                :global .button_common {
                    margin-top: 10px;
                    height: 65px;
                    font-size: 24px;
                }
            }
        }
    }
}
