.offical_site {
    width: 100%;

    .header {
        z-index: 2;
        width: 100%;
        height: 93px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 0 100px;
        gap: 90px;

        .opt_section_logo {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 30%;
            box-sizing: border-box;

            .top_logo {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .top_img {
                    width: 221px;
                    height: 76px;
                }
            }
        }

        .menu {
            min-width: 40%;
            height: 75%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 60px;
            overflow: hidden;

            .item {
                text-align: center;
                font-family: "Noto Sans";
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                color: #ffffff;
                cursor: pointer;
                position: relative;
            }
        }

        .opt_section {
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 30%;
            gap: 24px;

            .lng_container {
                width: 111px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                border-radius: 20px;
                background: linear-gradient(180deg, #eee 0%, #b073ec 149%);
                box-shadow: 3px 3px 3px 0px rgba(73, 75, 134, 0.3);
                color: #721ac9;
                font-family: "Noto Sans";
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 2px;
            }

            .btn {
                cursor: pointer;
                border: none;
            }

            .opt_start {
                height: 45px;
                width: 112px;
            }

            .start_game {
                height: 40px;
                width: 100px;
                font-family: "Noto Sans";
                border-radius: 20px;
                background: linear-gradient(180deg, #eee 0%, #b073ec 149%);
                box-shadow: 3px 3px 3px 0px rgba(73, 75, 134, 0.3);
                letter-spacing: 2px;
                font-weight: bold;
                font-stretch: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #721ac9;
                font-size: 16px;
            }
            .connect_wallet {
                width: 200px;
                height: 40px;
                flex-shrink: 0;
                border-radius: 20px;
                background: linear-gradient(180deg, #503666 0%, #7c1dc9 149%);
                box-shadow: 3px 3px 3px 0px rgba(73, 75, 134, 0.3);
                color: #fff;
                font-family: "Noto Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;

                .ant-image {
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }

                .text {
                    letter-spacing: 0;
                }
            }
        }
    }

    .panel {
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, #677aab 0%, #8562d4 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        gap: 50px;

        .logo {
            z-index: 1;
            width: 1084px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 50px;

            .logo_img {
                width: 900px;
                height: 480px;
            }
        }

        .button_common {
            z-index: 1;
            width: 250px;
            height: 95px;
            font-size: 36px;
            letter-spacing: 1px;
        }
    }

    .stroy {
        width: 100%;
        height: 100vh;
        background: linear-gradient(0deg, #667aaa 0%, #8660d6 100%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;

        .left_side {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .mode {
                width: 350px;
                height: 350px;
                background: #7f6dab;
                background-image: none;
                filter: drop-shadow(15px 15px 25px rgba(19, 18, 18, 0.25));
                border: #805cba 10px solid;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                .mode_img {
                    width: 320px;
                    height: 320px;
                    overflow: hidden;
                    border-radius: 50%;
                }
                .mode_img:hover {
                    transform: scale(1.2);
                    transition: transform 600ms;
                }
                .mode_title {
                    position: absolute;
                    display: flex;
                    align-items: self-start;
                    justify-content: center;
                    bottom: 0;
                    height: 25%;
                    width: 100%;
                    line-height: 120%;
                    font-size: 60px;
                    font-family: "Noto Sans";
                    background: #7f6dab;
                    -webkit-text-stroke: 6px #333333;
                    paint-order: stroke;
                    stroke-linejoin: round;
                    text-shadow: 5px 5px 5px rgba(51, 51, 51, 0.2);
                }
                .mode_title::before {
                    content: attr(data-title);
                    position: absolute;
                    background-image: linear-gradient(90.73deg, #e0dae2 6.46%, #df95cb 92.35%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 0;
                }
            }

            .inner_left {
                height: 95%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 80px;
            }

            .inner_right {
                height: 95%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .right_side {
            .title {
                color: #00db51;
                -webkit-text-fill-color: #00db51;
            }
        }
    }

    @keyframes block_rotation {
        0% {
            -webkit-transform: rotateZ(0deg);
        }
        100% {
            -webkit-transform: rotateZ(90deg);
        }
    }

    @-webkit-keyframes block_rotation {
        0% {
            -webkit-transform: rotateZ(0deg);
        }
        100% {
            -webkit-transform: rotateZ(90deg);
        }
    }

    @-webkit-keyframes wobble-vertical-on-hover {
        16.65% {
            -webkit-transform: translateY(8px);
            transform: translateY(8px);
        }
        33.3% {
            -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
        }
        49.95% {
            -webkit-transform: translateY(4px);
            transform: translateY(4px);
        }
        66.6% {
            -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
        }
        83.25% {
            -webkit-transform: translateY(1px);
            transform: translateY(1px);
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }

    @keyframes wobble-vertical-on-hover {
        16.65% {
            -webkit-transform: translateY(8px);
            transform: translateY(8px);
        }
        33.3% {
            -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
        }
        49.95% {
            -webkit-transform: translateY(4px);
            transform: translateY(4px);
        }
        66.6% {
            -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
        }
        83.25% {
            -webkit-transform: translateY(1px);
            transform: translateY(1px);
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    @keyframes run1 {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(100%);
        }
    }

    @keyframes run2 {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(100%);
        }
    }

    @keyframes run3 {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(-100%);
        }
    }

    @keyframes run4 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(-100%);
        }
    }

    .mode {
        width: 100%;
        height: 100vh;
        background: linear-gradient(0deg, #8660d6 0%, #667aaa 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        position: relative;

        .left_side {
            .title {
                color: #fe78c0;
                -webkit-text-fill-color: #fe78c0;
            }
        }

        .right_side {
            z-index: 1;
            width: 50%;
            height: 900px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .carousel {
                width: 100%;
                height: 100%;

                .coustom_style {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    .inner {
                        filter: blur(3px);
                        width: 300px;
                        height: 300px;
                        z-index: 1;
                        background-size: 100% 100%;
                        background: #7f6dab;
                        border: #805cba 10px solid;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        margin: 30px 30px 30px 0px;

                        .img_1 {
                            overflow: hidden;
                            border-radius: 50%;
                            width: 280px;
                            height: 280px;
                        }

                        .mode_title {
                            position: absolute;
                            display: flex;
                            align-items: self-start;
                            justify-content: center;
                            bottom: 0;
                            height: 25%;
                            width: 100%;
                            line-height: 120%;
                            font-size: 60px;
                            font-family: "Noto Sans";
                            // background: #7f6dab;
                            -webkit-text-stroke: 6px #333333;
                            paint-order: stroke;
                            stroke-linejoin: round;
                            text-shadow: 5px 5px 5px rgba(51, 51, 51, 0.2);
                        }
                        .mode_title::before {
                            content: attr(data-title);
                            position: absolute;
                            background-image: linear-gradient(90.73deg, #e0dae2 6.46%, #df95cb 92.35%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -webkit-text-stroke: 0;
                        }
                        .mode1 {
                            div:nth-child(1) {
                                cursor: pointer;
                                position: absolute;
                                width: 200px;
                                height: 200px;
                                top: -215px;
                                background: url(../assets/icon/block_s.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:hover {
                                animation: block_rotation 0.5s 1 linear;
                            }
                        }
                        .mode2 {
                            div:nth-child(1) {
                                cursor: pointer;
                                position: absolute;
                                width: 150px;
                                height: 150px;
                                top: -200px;
                                right: 40px;
                                background: url(../assets/icon/block_o.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:nth-child(2) {
                                cursor: pointer;
                                position: absolute;
                                width: 150px;
                                height: 150px;
                                top: -150px;
                                left: 20px;
                                background: url(../assets/icon/block_o.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:hover {
                                animation: block_rotation 0.5s 1 ease-in;
                            }
                        }
                        .mode3 {
                            div:nth-child(1) {
                                position: absolute;
                                cursor: pointer;
                                width: 150px;
                                height: 150px;
                                right: 30px;
                                top: -250px;
                                background: url(../assets/icon/block_s.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:nth-child(2) {
                                position: absolute;
                                cursor: pointer;
                                width: 150px;
                                height: 150px;
                                left: 30px;
                                top: -250px;
                                background: url(../assets/icon/block_o.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:nth-child(3) {
                                position: absolute;
                                cursor: pointer;
                                width: 230px;
                                height: 90px;
                                top: -90px;
                                background: url(../assets/icon/block_i.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:hover {
                                animation: block_rotation 0.5s 1 ease-in;
                            }
                        }
                        .mode4 {
                            div:nth-child(1) {
                                position: absolute;
                                cursor: pointer;
                                width: 154px;
                                height: 154px;
                                top: -170px;
                                background: url(../assets/icon/block_4.png) 100% no-repeat;
                                background-size: 100% 100%;
                            }
                            div:hover {
                                animation: wobble-vertical-on-hover 1s 1 ease-in-out;
                            }
                        }
                    }
                    .inner_active {
                        width: 368px;
                        height: 368px;
                        margin-left: 0px;
                        z-index: 999;
                        filter: drop-shadow(5px 5px 25px rgba(19, 18, 18, 0.25)) blur(0px);
                        margin: 0px;
                        position: relative;
                        left: -30px;
                        .img_1 {
                            width: 348px;
                            height: 348px;
                        }
                    }
                    .card_content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .btn_group {
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;

                .text {
                    font-family: "Noto Sans";
                    color: #fafafa;
                    text-shadow:
                        #7c7c7c 1px 0 0,
                        #7c7c7c 0 1px 0,
                        #7c7c7c -1px 0 0,
                        #7c7c7c 0 -1px 0;
                    color: #fafafa;
                    text-align: center;
                    font-weight: 900;
                    line-height: 30px;
                    letter-spacing: 6.2px;
                    font-size: 62px;
                }

                .btn {
                    width: 90px;
                    height: 90px;
                    .ant-image {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .nft {
        width: 100%;
        height: 100vh;
        background: linear-gradient(0deg, #667aaa 0%, #8660d6 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        gap: 50px;

        .left_side {
            z-index: 1;
            width: 50%;
            height: 900px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .carousel {
                width: 100%;
                height: 100%;

                .coustom_style {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    .inner {
                        filter: blur(3px);
                        width: 300px;
                        height: 300px;
                        z-index: 1;
                        background-size: 100% 100%;
                        background: #7f6dab;
                        border: #805cba 10px solid;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        margin: 30px 30px 30px 0px;

                        .img_1 {
                            overflow: hidden;
                            border-radius: 50%;
                            width: 280px;
                            height: 280px;
                        }

                        .mode_title {
                            position: absolute;
                            display: flex;
                            align-items: self-start;
                            justify-content: center;
                            bottom: 0;
                            height: 25%;
                            width: 100%;
                            line-height: 120%;
                            font-size: 60px;
                            font-family: "Noto Sans";
                            background: #7f6dab;
                            -webkit-text-stroke: 6px #333333;
                            paint-order: stroke;
                            stroke-linejoin: round;
                            text-shadow: 5px 5px 5px rgba(51, 51, 51, 0.2);
                        }
                        .mode_title::before {
                            content: attr(data-title);
                            position: absolute;
                            background-image: linear-gradient(90.73deg, #e0dae2 6.46%, #df95cb 92.35%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -webkit-text-stroke: 0;
                        }
                    }
                    .inner_active {
                        width: 368px;
                        height: 368px;
                        margin-left: 0px;
                        z-index: 999;
                        filter: drop-shadow(5px 5px 25px rgba(19, 18, 18, 0.25)) blur(0px);
                        margin: 0px;
                        position: relative;
                        left: -30px;
                        .img_1 {
                            width: 348px;
                            height: 348px;
                        }
                    }
                    .card_content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .btn_group {
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;

                .text {
                    font-family: "Noto Sans";
                    color: #fafafa;
                    text-shadow:
                        #7c7c7c 1px 0 0,
                        #7c7c7c 0 1px 0,
                        #7c7c7c -1px 0 0,
                        #7c7c7c 0 -1px 0;
                    color: #fafafa;
                    text-align: center;
                    font-weight: 900;
                    line-height: 30px;
                    letter-spacing: 6.2px;
                    font-size: 62px;
                }

                .btn {
                    width: 90px;
                    height: 90px;
                    .ant-image {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .right_side {
            .title {
                color: #bd6cff;
                -webkit-text-fill-color: #bd6cff;
            }
        }
    }

    .route {
        width: 100%;
        height: 112.5vw;
        background: linear-gradient(0deg, #721ac9 0%, #667aaa 100%);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        position: relative;

        .route_bg {
            width: 74vw;
            height: 112.5vw;
            background-image: url("../assets/icon/route_bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            position: relative;
            margin: 0 auto;
        }

        .popup {
            z-index: 1;
            width: 120px;
            height: 120px;
            background-image: url("../assets/icon/popup.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 18px;
            font-family: "Noto Sans";
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 480px;
            left: 510px;
            box-sizing: border-box;
            word-break: break-all;
            text-align: center;
            text-shadow:
                #333 1px 0 0,
                #333 0 1px 0,
                #333 -1px 0 0,
                #333 0 -1px 0;
        }

        .pic_position {
            width: 280px;
            height: 280px;
            background-image: none;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 550px;
            left: 650px;

            .nft_pic {
                width: 262px;
                height: 262px;
            }
        }

        .position_1 {
            top: 430px;
            left: 830px;
        }

        .position_2 {
            top: 840px;
            left: 580px;
        }

        .position_3 {
            top: 850px;
            left: 1080px;
        }

        .position_4 {
            top: 720px;
            left: 1260px;
        }

        .position_5 {
            top: 900px;
            left: 1360px;
        }

        .position_6 {
            top: 1100px;
            left: 1300px;
        }

        .position_7 {
            top: 1180px;
            left: 540px;
        }

        .position_8 {
            top: 1330px;
            left: 380px;
        }

        .position_9 {
            top: 1550px;
            left: 460px;
        }

        .position_10 {
            top: 1600px;
            left: 630px;
        }

        .position_11 {
            top: 1310px;
            left: 540px;
        }

        .position_12 {
            top: 1700px;
            left: 1200px;
        }

        .position_13 {
            top: 1540px;
            left: 1360px;
        }

        .position_14 {
            top: 1800px;
            left: 1530px;
        }

        .position_15 {
            top: 1980px;
            left: 1420px;
        }

        .position_16 {
            top: 2000px;
            left: 1130px;
        }
    }

    .footer_ow {
        width: 100%;
        height: 80px;
        background: #721ac9;
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;

        .container {
            width: 85%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                width: 60%;
                height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .slogan_des {
                    font-size: 18px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #fff;
                    line-height: 35px;
                    text-shadow: 5px 5px 5px rgba(136, 109, 196, 0.5);
                    display: none;
                }

                .reserved {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    color: #fff;
                    font-size: 21px;
                    font-family: "Noto Sans";
                    font-weight: 700;
                    line-height: 50px;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: center;

                .join {
                    font-size: 21px;
                    font-family: "Noto Sans";
                    font-weight: normal;
                    color: #fff;
                    display: none;
                }

                .social {
                    gap: 20px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .btn {
                        cursor: pointer;
                    }

                    .tw {
                        width: 20px;
                        height: 20px;
                    }

                    .fb {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    .footer_ow_hover {
        height: 180px;

        .container {
            .left {
                justify-content: space-between;

                .slogan_des {
                    display: flex;
                }
            }
            .right {
                .join {
                    display: flex;
                }
                .social {
                    margin-top: 20px;
                }
            }
        }
    }

    .casting {
        width: 100%;
        min-height: calc(100vh - 80px);
        background: linear-gradient(0deg, #8660d6 0%, #667aaa 100%);
        padding-top: 93px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        gap: 28px;

        .web_title {
            width: 563px;
        }

        .casting_subtitle {
            width: 1300px;
            height: 153px;
            flex-shrink: 0;
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            background: rgba(64, 7, 126, 0.2);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #240643;
            font-family: "Noto Sans";
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            .casting_discount_img {
                position: absolute;
                bottom: 0;
                left: 20px;
                width: 200px;
                height: 200px;
                background: url("../assets/web/discount_img.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
        }

        .casting_content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 150px;
            padding-top: 28px;
            z-index: 1;

            .banner {
                width: 496px;
                height: 496px;
                flex-shrink: 0;
                background: #9f9feb;
                border-radius: 15px;
                overflow: hidden;
                box-shadow: 5px 5px 25px 10px rgba(60, 43, 74, 0.3);

                .ant-carousel .slick-dots {
                    position: relative;
                    top: -50px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    li button {
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                    }
                }

                .slick-dots li button:before,
                .slick-dots li.slick-active button:before {
                    display: none;
                }

                .ant-carousel .slick-dots li.slick-active button {
                    background-color: #484444;
                }
            }

            .casting_price {
                width: 496px;
                height: 496px;
                flex-shrink: 0;
                border: 12px solid;
                border-image: linear-gradient(225deg, #4a7da1, #c9c2ee, #995599) 1 1;
                background: rgba(25, 22, 41, 0.6);
                clip-path: inset(0 round 12px);
                box-sizing: border-box;
                padding: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 40px;
                position: relative;

                .price {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;

                    .limited {
                        display: flex;
                        align-items: center;
                        width: 152px;
                        height: 28px;
                        flex-shrink: 0;
                        border-radius: 6px;
                        background: #9567d1;
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-align: center;
                        .limited_title {
                            width: 78px;
                        }
                        .limited_subtitle {
                            width: 78px;
                            border-radius: 0px 6px 6px 0px;
                            background: #fff;
                            height: 28px;
                            color: #9567d1;
                            line-height: 28px;
                        }
                    }

                    .limit {
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    .casting_price_title {
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        display: flex;
                        gap: 24px;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .casting_price_price {
                    display: flex;
                    gap: 24px;
                    align-items: center;
                    justify-content: center;

                    .regular_price {
                        color: #65f563;
                        font-family: "Noto Sans";
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                    .discount_price {
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-decoration: line-through;
                    }
                }

                .casting_price_ammout {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 74px;
                    color: #fff;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    .arrow_button {
                        width: 40px;
                        height: 40px;
                    }
                }

                .casting_action_button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;

                    .button_common {
                        font-size: 24px;
                        height: 65px;
                    }

                    .buy_vsys {
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }

                .casting_settings {
                    top: 20px;
                    right: 20px;
                    position: absolute;
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .setting_icon {
                        width: 35px;
                        height: 35px;

                        .ant-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .off20 {
                    width: 110px;
                    height: 38px;
                    flex-shrink: 0;
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    border-radius: 13px;
                    background: #70699e;
                    box-shadow: 3px 3px 0px 0px #2f2b4d;
                    color: #fff;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 38px;
                }
            }
        }
    }
    .casting_hover {
        min-height: calc(100vh - 180px);
    }
    .huobi_active,
    .dc_active {
        position: absolute;
        left: 80px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .huobi_active_div,
        .dc_active_div {
            z-index: 5;
            width: 265px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .active_content {
                width: 265px;
                border-radius: 20px;
                background: rgba(25, 22, 41, 0.7);
                box-shadow: 10px 10px 15px 0px #463a74;
                backdrop-filter: blur(15px);
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 30px 0;
                box-sizing: border-box;
                gap: 20px;

                .ant-image {
                    width: 100%;
                    height: 100%;
                }

                .active_content1_img {
                    width: 205px;
                    height: 187px;
                }

                .active_content2_img {
                    width: 108px;
                    height: 89px;
                }

                .invite_earn_img {
                    width: 308px;
                    height: 272px;
                }

                .active_huobi_img {
                    width: 250px;
                    height: 104px;
                }

                .active_time {
                    color: #a796fa;
                    text-align: center;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .active_content_content {
                    width: 60%;
                }

                .button_common {
                    height: 50px;
                    font-size: 24px;
                    text-decoration: none;
                }

                .dc_img {
                    width: 160px;
                    height: 160px;
                }

                .invite {
                    right: 20px;
                    top: 20px;
                }
                .active_button {
                    width: 29px;
                    height: 29px;
                    border-radius: 8px;
                    background: #272640;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    padding: 0;

                    .ant-image {
                        width: 10px;
                        height: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .active_button {
                width: 50px;
                height: 50px;
                background-color: rgba(23, 20, 41, 0.7);
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .ant-image {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .dc_active {
        left: auto;
        right: 80px;
        .dc_active_div {
            cursor: pointer;
        }
        .dc_active_div:hover .active_content {
            color: #a796fa;
        }
    }

    .running_light {
        position: relative;
        z-index: 1;
        width: 480px;
        height: 900px;
        padding: 30px;
        background: rgba(25, 22, 41, 0.6);

        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .title {
            font-family: "Noto Sans";
            font-size: 38px;
            line-height: 50px;
            height: 50px;
            margin-bottom: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            letter-spacing: 3.8px;
            text-shadow: none;
        }

        .content {
            width: 100%;
            height: calc(900px - 280px);
            display: flex;
            align-items: flex-start;
            .p {
                font-size: 24px;
                font-family: "Noto Sans";
                font-weight: 400;
                color: #fff;
                white-space: pre-line;
            }
        }

        .start {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;

            .button_common {
                width: 200px;
                height: 70px;
                font-size: 24px;
                font-weight: 900;
            }
        }
    }
    .running_light::after {
        content: "";
        position: absolute;
        top: -12px;
        left: -12px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 12px solid transparent;
        border-image-source: linear-gradient(225deg, #4a7da1, #c9c2ee, #995599);
        border-image-slice: 20;
        border-image-width: 12px;
        border-image-outset: 0;
        border-image-repeat: stretch;
        clip-path: inset(0px round 12px);
    }
    .running_light .run:nth-child(1) {
        z-index: 1;
        position: absolute;
        top: -12px;
        left: 0px;
        width: calc(100% + 12px);
        height: 12px;
        filter: blur(2px);
        box-shadow:
            2px 2px 20px #ffffff66,
            2px -2px 20px #ffffff66;
        overflow: hidden;
        border-radius: 0px 20px 0 0;
    }
    .running_light .run:nth-child(1)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: run1 2s linear infinite;
        animation-delay: 0s;
        background: linear-gradient(to right, #ffffff66, #01b7ffa3, #e354e39d, #ffffff66);
    }
    .running_light .run:nth-child(2) {
        z-index: 1;
        position: absolute;
        top: 0px;
        right: -12px;
        height: calc(100% + 12px);
        width: 12px;
        filter: blur(2px);
        box-shadow:
            2px 2px 20px #ffffff66,
            2px -2px 20px #ffffff66;
        overflow: hidden;
        border-radius: 0px 0px 20px 0;
    }
    .running_light .run:nth-child(2)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: run2 2s linear infinite;
        animation-delay: 1s;
        background: linear-gradient(to bottom, #ffffff66, #01b7ffa3, #e354e39d, #ffffff66);
    }
    .running_light .run:nth-child(3) {
        z-index: 1;
        position: absolute;
        bottom: -12px;
        left: -12px;
        height: 12px;
        width: calc(12px + 100%);
        filter: blur(2px);
        box-shadow:
            2px 2px 20px #ffffff66,
            2px -2px 20px #ffffff66;
        overflow: hidden;
        border-radius: 0px 0px 0px 20px;
    }
    .running_light .run:nth-child(3)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: run3 2s linear infinite;
        animation-delay: 2s;
        background: linear-gradient(to left, #ffffff66, #01b7ffa3, #e354e39d, #ffffff66);
    }
    .running_light .run:nth-child(4) {
        z-index: 1;
        position: absolute;
        top: -12px;
        left: -12px;
        height: calc(100% + 12px);
        width: 12px;
        filter: blur(2px);
        box-shadow:
            2px 2px 20px #ffffff66,
            2px -2px 20px #ffffff66;
        overflow: hidden;
        border-radius: 20px 0 0 0;
    }
    .running_light .run:nth-child(4)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: run4 2s linear infinite;
        animation-delay: 3s;
        background: linear-gradient(to top, #ffffff66, #01b7ffa3, #e354e39d, #ffffff66);
    }

    .invite_page {
        min-height: calc(100vh - 80px);
        background: #3c205a;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .invite_page_bg {
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 0;

            .invite_page_bg1 {
                width: 100%;
                height: 1110px;
                background-image: url("../assets/web/invite_page_bg.png");
                background-size: 100% auto;

                .invite_page_bg1_bg {
                    width: 100%;
                    height: 1110px;
                    background: linear-gradient(180deg, rgba(103, 57, 153, 0) 15.36%, #3c205a 91.08%);
                }
            }
        }

        .invite_page_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            position: relative;
            top: 0;
            z-index: 1;
            width: 100%;
            padding-top: 200px;
            padding-bottom: 108px;
            color: #fff;
            // text-align: center;

            .invite_page_title_div {
                border-radius: 40px;
                background: rgba(5, 5, 5, 0.7);
                backdrop-filter: blur(25px);
                display: flex;
                flex-direction: column;
                gap: 12px;
                align-items: center;
                justify-content: center;
                min-width: 675px;
                padding: 0 40px;
                height: 197px;
                flex-shrink: 0;

                .invite_page_title_title {
                    color: #fff;
                    text-align: center;
                    font-family: "Turret Road";
                    font-size: 80px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .invite_page_title_content {
                    color: #fff;
                    text-align: center;
                    font-family: "Titillium Web";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .invite_page_time {
                border-radius: 40px;
                background: rgba(5, 5, 5, 0.7);
                backdrop-filter: blur(25px);
                padding: 20px 50px;
                color: #fff;
                text-align: center;
                font-family: "Turret Road";
                font-size: 80px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 8px;
            }

            .invite_page_code {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding: 100px 0 50px 0;

                .invite_page_code_input {
                    border-radius: 40px;
                    background: rgba(5, 5, 5, 0.7);
                    backdrop-filter: blur(25px);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 675px;
                    height: 71px;
                    padding: 0 30px;
                    color: #fff;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .invite_page_code_title {
                    color: #fff;
                    text-align: center;
                    font-family: "Turret Road";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 3.2px;
                }
            }

            .invite_page_step {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                .invite_page_step_content {
                    width: 279px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    .invite_page_step_content_title {
                        color: #fff;
                        text-align: center;
                        font-family: "Turret Road";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        letter-spacing: 3.2px;
                    }

                    .invite_page_step_content_content {
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 2.4px;
                    }
                }

                .invite_page_step_space {
                    width: 158px;
                    height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .invite_page_step_space_img {
                        width: 158px;
                        height: 10px;
                    }
                }
            }

            .invite_page_rest {
                width: 1600px;
                height: 300px;
                border-radius: 40px;
                background: rgba(5, 5, 5, 0.15);
                backdrop-filter: blur(25px);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 192px;
                margin: 10px 0 50px 0;

                .invite_page_rest_invites {
                    display: flex;
                    flex-direction: column;
                    gap: 22px;
                    width: 279px;
                    align-items: center;
                    justify-content: center;

                    .invite_page_rest_invites_img1 {
                        width: 96.708px;
                        height: 73.879px;
                        flex-shrink: 0;
                    }

                    .invite_page_rest_invites_img2 {
                        width: 93.528px;
                        height: 93px;
                        flex-shrink: 0;
                    }

                    .invite_page_rest_invites_title {
                        color: #fff;
                        text-align: center;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 1.6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                    }

                    .invite_page_rest_invites_number {
                        color: #fff;
                        text-align: center;
                        font-family: "Turret Road";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        letter-spacing: 3.2px;
                    }
                }
            }

            .invite_page_collect {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 80px;

                .invite_page_collect_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 40px;
                    background: rgba(5, 5, 5, 0.7);
                    backdrop-filter: blur(25px);
                    width: 250px;
                    height: 250px;

                    .invite_page_collect_item_img {
                        width: 180px;
                        height: 180px;
                        flex-shrink: 0;
                    }

                    .invite_page_collect_item_button {
                        border-radius: 16px;
                        background: linear-gradient(178deg, #4f1a90 5.68%, #8428f4 98.16%);
                        box-shadow: 3px 3px 0px 0px #541c9b;
                        display: inline-flex;
                        padding: 10px 28px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        color: #fff;
                        font-family: "Noto Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border: none;
                        height: 40px;
                    }

                    .invite_page_collect_item_button_dis {
                        border-radius: 16px;
                        background: linear-gradient(178deg, #838383 5.68%, #e1e1e1 98.16%);
                        box-shadow: 3px 3px 0px 0px #919092;
                        cursor: no-drop;
                    }
                }
            }
        }

        .claim_nft {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100px;
            margin: auto auto;
            z-index: 3;
            width: 160px;
            height: 160px;
            cursor: pointer;

            .claim_nft_bg {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                background-color: rgba(243, 230, 230, 0.5);
                box-shadow:
                    5px 5px 3.5px rgba(24, 23, 23, 0.3),
                    0px 0px 25px 25px rgba(0, 0, 0, 0.05);
                backdrop-filter: blur(3.5px);
                display: flex;
                align-items: center;
                justify-content: center;

                .claim_nft_img {
                    width: 101.735px;
                    height: 138px;
                    flex-shrink: 0;
                }
            }
        }

        .claim_nft_dis {
            opacity: 0.5;
            cursor: no-drop;
        }
    }
}
.lng {
    width: 24px;
    height: 24px;
    background: url("../assets/icon/lng.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.arrow {
    width: 24px;
    height: 24px;
    background: url("../assets/icon/arrow.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.log_out {
    width: 24px;
    height: 24px;
    background: url("../assets/hall/log_out.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.trading {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 90px;
    position: relative;

    .trading_point {
        width: 399px;
        height: 240px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        .trading_item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            color: #40077e;
            text-align: right;
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            .trading_item_point {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 11px;

                .trading_input {
                    border-radius: 5px;
                    border: 1px solid rgba(255, 255, 255, 0.15);
                    background: #40077e;
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    color: rgba(255, 255, 255, 0.5);
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 40px;
                    text-align: center;
                }
                :global .ant-input::placeholder {
                    color: rgba(255, 255, 255, 0.5);
                }
            }

            .trading_item_title {
                color: #7650ca;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .trading_button {
        display: flex;
        align-items: center;
        justify-content: center;

        .button_common {
            height: 65px;
            font-size: 24px;
        }
    }

    .trading_warning {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 13px;
        position: absolute;
        width: 400px;
        bottom: 130px;
        color: #f01313;
        font-family: "Noto Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
