.hall-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #677aab 0%, #8562d4 100%);
    background-size: cover;

    .header {
        height: 100px;
        z-index: 1;
    }

    .content {
        height: calc(100% - 100px);
        z-index: 1;
        .spin_div {
            position: fixed;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(17, 15, 15, 0.6);
            z-index: 100;
            padding-top: 50vh;

            .ant-spin-text {
                text-shadow: none;
                color: #b0b0b0;
                font-size: 40px;
            }
        }
    }
}
