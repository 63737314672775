.player_bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../assets/icon/opt_bg.png") 100% no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .players_title {
        width: 100%;
        height: 10%;
        line-height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        font-family: "Noto Sans";
        font-weight: normal;
        color: #e2f4ff;
        text-shadow: 0px 4px 2px rgba(7, 70, 98, 0.46);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        span {
            width: 70%;
            height: 100%;
            line-height: 180%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
        }

        .ant-image {
            height: 50%;

            img {
                height: 100%;
            }
        }
    }

    .focus {
        position: absolute;
        top: 400;
    }

    .content {
        height: 85%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
