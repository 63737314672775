.buy_detail {
    .remove_nft {
        width: 786px;
        min-height: 500px;
    }
    .nft_loading {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nft_detail {
        display: flex;
        width: 786px;
        box-sizing: border-box;
        height: calc(100% - 150px);
        gap: 30px;

        .nft_right {
            display: flex;
            flex-direction: column;
            width: 300px;
            gap: 4px;

            .nft_img {
                width: 300px;
                height: 300px;
                border-radius: 10px;
            }

            .nft_series {
                width: 100%;
                color: #4f1a90;
                font-family: "Noto Sans";
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;

                .series {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: #4f1a90;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 36px;
                }
            }

            .nft_ear {
                color: #721ac9;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .nft_left {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
            width: 456px;

            .nft_price {
                .tit {
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 36px;
                }
                .price {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #40077e;
                    text-align: right;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 48px;

                    .icon {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }

                    .price_usd {
                        color: #40077e;
                        text-align: right;
                        font-family: "Noto Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 36px;
                    }
                }
                .ant-statistic-content {
                    display: flex;
                    align-items: center;
                    color: #40077e;
                    text-align: right;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 48px;
                }
            }

            .buy_info {
                width: 100%;
                height: 116px;
                border-radius: 13px;
                background: rgba(255, 255, 255, 0.3);
                box-sizing: border-box;
                padding: 26px 15px;

                .tit {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    color: #40077e;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px;
                }

                .info {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    color: #40077e;
                    text-align: right;
                    font-family: "Noto Sans";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 48px;
                    form {
                        width: 100%;
                        display: flex;
                        gap: 20px;
                    }
                    .icon_info {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }
                    .form_input {
                        height: 40px !important;
                        border-radius: 10px !important;
                        .ant-input {
                            font-size: 18px !important;
                        }
                        .ant-image {
                            img {
                                width: 30px !important;
                            }
                        }
                    }
                    .button_common {
                        height: 40px;
                        font-size: 14px;
                    }
                }
            }

            .buy_fee {
                border-top: #a363ff solid 6px;

                .tit {
                    color: #7c29d4;
                    font-family: "Noto Sans";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px;
                }
                .tit_content {
                    color: #7650ca;
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
                .fee {
                    display: flex;
                    gap: 50px;
                    align-items: center;
                    color: #2e2139;
                    text-align: right;
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .nft_input {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 16px;

                .input_title {
                    color: #2e2139;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .input_content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;

                    .button {
                        padding: 0;
                        overflow: hidden;
                    }

                    .dropdown {
                        min-width: 120px;
                        max-width: 120px;
                        padding: 0 12px;
                    }
                }

                .dropdown,
                .button {
                    height: 40px;
                    border-radius: 12px;
                    border: 1px solid #2e2139;
                    background: #fff;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 12px;
                    box-sizing: border-box;
                    color: #2e2139;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .nft_message {
                height: 50px;
                display: flex;
                align-items: center;
                gap: 10px;

                .message_title {
                    font-family: "Noto Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }

                .message_success {
                    color: #40077e;
                }

                .message_filed {
                    color: #f00;
                }
            }
        }
    }
    .nft_button {
        display: flex;
        width: 100%;
        gap: 20px;
        height: 100px;
        align-items: flex-start;
        justify-content: center;

        .button_common {
            font-size: 24px;
            height: 65px;
        }
    }
}
