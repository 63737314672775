.nft-list-containerxx {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 740px;

    .page_div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #4f1a90;
        height: 60px;
        width: 100%;
    }

    .nft_list {
        width: 100%;
        height: 556px;
    }

    .nft-list {
        width: 100%;
        height: 556px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 16px 51px;

        .nft-item {
            width: 200px;
            height: 270px;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            position: relative;

            .locked_seconds {
                position: absolute;
                top: 10px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                height: 90px;
                width: 100%;
                color: #ed5e25;
                line-height: 40px;
                text-align: center;
                div {
                    color: #333;
                    height: 20px;
                    font-size: 14px;
                    width: 90px;
                }
                span {
                    height: 40px;
                    width: 100%;
                    font-size: 40px;
                    font-weight: 700;
                }
            }

            .nft_detail {
                display: flex;
                flex-direction: column;
                width: 200px;
                border-radius: 0px 0px 8px 8px;
                background: rgba(255, 255, 255, 0.5);

                .nft_id {
                    color: #6e41d1;
                    font-family: "Noto Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 6px 12px;
                }
                .line {
                    width: 100%;
                }
                .nft_type {
                    font-family: "Noto Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 6px 12px;
                    border: none;
                }
            }
            .nft_img {
                width: 200px;
                height: 200px;
                border-radius: 8px 8px 0 0;
                overflow: hidden;
                position: relative;
                border-width: 3px;
                .antd-img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .select {
            background: #d0d0d0;
        }
        .nft-disabled {
            cursor: not-allowed !important;
            opacity: 0.7;
        }
    }

    .nft-item-select {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;

        .nft-item-select-img {
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            border-radius: 16px;
            background-color: #fff;

            .nft-img {
                width: 300px;
                height: 300px;
                border-radius: 16px 16px 0 0;
                overflow: hidden;
                position: relative;
                .antd-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .nft-id {
                color: #6e41d1;
                font-family: "Noto Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 12px 0;
            }
        }

        .nft-item-select-detail {
            width: 500px;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .input {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .input_div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
                .msg_info {
                    color: #2a005e;
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    cursor: pointer;
                }
                .msg_info_err {
                    font-family: "Noto Sans";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: rgba(218, 20, 20, 0.72);
                }
            }

            .wallet_address {
                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .option_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 10px;
        gap: 10px;
        .button_common {
            height: 65px;
            font-size: 24px;
        }
    }
}
