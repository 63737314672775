.transfer_in_token {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 32px 23px;

    .tab_top {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        :global .price_ic {
            width: 50px;
            height: 50px;
        }

        .price_usd {
            color: #40077e;
            font-family: "Noto Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 300px;
        }

        .price_tet {
            color: #40077e;
            font-family: "Noto Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .token_list {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;

        .token_item {
            width: 563px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .form_input {
                border-radius: 16px;
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #40077e;
                width: 563px;
                height: 71px;
                flex-shrink: 0;
                color: rgba(255, 255, 255, 0.5);
                font-family: "Noto Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 0 20px;
                box-sizing: border-box;
                line-height: 70px;
            }
            :global .ant-input::placeholder {
                color: rgba(255, 255, 255, 0.5);
            }

            .form_input_info {
                color: #2a005e;
                font-family: "Noto Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                .info_error {
                    color: #f01313;
                }
            }
        }
        .select {
            // background: #d4d4d4;

            .form_input {
                border-radius: 16px;
                border: 2px solid #fff;
                background: #2a0850;
                color: #fff;
                font-weight: 800;
                font-size: 26px;
            }
        }
    }

    .bt_div {
        width: 1150px;
        display: flex;
        justify-content: center;
        align-items: center;

        :global .button_common {
            height: 65px;
            font-size: 24px;
        }
    }
}
